import React, { useEffect, useState } from "react";
import Grid from "../Home/PageListing/Grid/Grid";
import Spinner from "../../component/Spinner/Spinner";
const HomePageGrid = ({
  products,
  title,
  toggleView,
  emptyString,
  searchItem,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (products.length > 0) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [products.length]);
  return (
    <div className="mb-4">
      <h2 className="home-title  mb-2">{title}</h2>
      <div className="divide" />
      {loading ? (
        <Spinner color="#377abf" padding={20} />
      ) : (
        <div className={toggleView ? "custom-row" : "row"}>
          {toggleView ? (
            <>
              {emptyString === "" && products.length > 0 ? (
                products?.map((item, index) => (
                  <Grid item={item} index={index} isGridView={true} />
                ))
              ) : emptyString && searchItem ? (
                <div class="empty-search-string">{emptyString}</div>
              ) : null}
            </>
          ) : (
            <div className="list-view col-12 row">
              {emptyString === "" && products.length > 0 ? (
                products?.map((item, index) => (
                  <Grid item={item} index={index} isGridView={false} />
                ))
              ) : emptyString && searchItem ? (
                <div class="empty-search-string">{emptyString}</div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePageGrid;
