/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setLogout } from "../../../redux/actions/coilAction";

const Start = ({ setLogout }) => {
  const navigator = useNavigate();

  useEffect(() => {
    redirectLogin();
  }, []);
  const redirectLogin = () => {
    if (JSON.parse(localStorage.getItem("userDetail"))?.email) {
      if (
        JSON.parse(localStorage.getItem("userDetail"))?.authority ===
        "CUSTOMER_USER"
      ) {
        navigator("/customer/snapshot");
      } else {
        navigator("/home");
      }
    } else {
      navigator("/login");
      localStorage.clear();
      setLogout({});
    }
  };
  return (
    <div className="layout-box">
      <div style={{ overflow: "auto" }}>
        <div>
          <div
            className="w-full flex align-items-center justify-content-center"
            style={{ height: "500px" }}
          >
            <div className="empty-text">
              Begin by Choosing Your AHU SmartCoil Gateway
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { setLogout })(Start);
