import {
  ADD_BUILDING,
  ADD_RELATION,
  REMOVE_BUILDING,
  UPDATE_BUILDING,
  ACTIVE_BUILDING,
  BUILDINGLIST,
  RESET_ACTION,
} from "../constant";

export const BuildingList = (payload) => ({
  type: BUILDINGLIST,
  payload: { payload },
});

export const addBuilding = (payload) => ({
  type: ADD_BUILDING,
  payload: payload,
});
export const addRelation = (payload) => ({
  type: ADD_RELATION,
  payload: payload,
});

export const updateBuilding = (payload) => ({
  type: UPDATE_BUILDING,
  payload: payload,
});

export const activeBuilding = (payload) => ({
  type: ACTIVE_BUILDING,
  payload: payload,
});

export const removeBuilding = (payload) => ({
  type: REMOVE_BUILDING,
  payload: payload,
});

export const setLogout = (payload) => ({
  type: RESET_ACTION,
  payload: payload,
});
