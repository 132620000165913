import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Buildings from "./Buildings/Buildings";
import Profile from "./Profile";
import SmartCoil from "./SmartCoil/SmartCoil";
import Customers from "./Customers/Customers";
import CustomersUserRouter from "./CustomerUserRouter/CustomerUserRouter";
import { useEffect } from "react";
const AdminConsole = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabs, setActiveTabs] = useState(0);
  const [isUser, setIsUser] = useState(false);
  const [totalElement, setTotalElement] = useState("");
  const [userTableJson, setUserTableJson] = useState("");
  const [breadcrumb, setBreadcrumb] = useState("");
  const [CurrentCustomer, setCurrentCustomer] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sendDataToParent = (
    userTableJson,
    totalElement,
    Breadcrumb,
    CustomerId
  ) => {
    setUserTableJson(userTableJson);
    setTotalElement(totalElement);
    setIsUser(true);
    searchParams.delete("tab");
    setSearchParams(searchParams);
    setBreadcrumb(Breadcrumb);
    setCurrentCustomer(CustomerId);
  };

  useEffect(() => {
    if (searchParams.get("tab") !== null) {
      setIsUser(false);
      setActiveTabs(parseInt(searchParams.get("tab")));
    }
  }, [searchParams]);
  const openModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  return (
    <div className="container">
      <h6 className="admin-title">
        {JSON.parse(localStorage.userDetail)?.authority === "CUSTOMER_USER"
          ? "Profile"
          : "Admin Console"}
      </h6>
      {JSON.parse(localStorage.userDetail)?.authority !== "CUSTOMER_USER" ? (
        <div className="d-flex flex-wrap mt-5 ">
          {["Profile", "Customers", "Buildings", "SmartCoil Gateways"]?.map(
            (item, index) =>
              index === 1 &&
              JSON.parse(localStorage.userDetail)?.authority ===
                "CUSTOMER_USER" ? null : (
                <div
                  key={item + index}
                  className={`admin-tabs ${
                    activeTabs === index ? "active" : ""
                  } mb-sm-1`}
                  onClick={() => {
                    setActiveTabs(index);
                    setIsUser(false);
                  }}
                >
                  {item}
                </div>
              )
          )}
        </div>
      ) : (
        <></>
      )}
      {isUser ? (
        <div>
          <div className="divide mt-3" />
          <div className="user-title-btn-wrapper">
            <div className="d-flex align-items-center">
              <span
                className="btn mt-3 mb-3 d-flex p-0"
                onClick={() => {
                  setIsUser(false);
                  setActiveTabs(1);
                }}
              >
                <b>{breadcrumb}</b>
              </span>
              <span> &nbsp;{">"} Users</span>
            </div>

            <button
              type="button"
              className="btn btn-outline-secondary btn-sm new-user-btn"
              onClick={openModal}
            >
              Add New User
            </button>
          </div>

          <h6 className="admin-subtitle">Users</h6>
          <div className="new-user-btn-wrapper float-md-right"></div>
          {activeTabs === 1 ? (
            <CustomersUserRouter
              isUser={isUser}
              isModalIsOpen={modalIsOpen}
              CurrentCustomer={CurrentCustomer}
              userTableJson={userTableJson}
              totalElement={totalElement}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
          <div className="divide mt-3" />
          <h6 className="admin-subtitle">
            {activeTabs === 0
              ? ""
              : activeTabs === 1
              ? "Customers"
              : activeTabs === 2
              ? "Buildings"
              : "SmartCoil Gateways"}
          </h6>
          {activeTabs === 0 ? (
            <Profile />
          ) : activeTabs === 1 ? (
            <Customers sendDataToParent={sendDataToParent} />
          ) : activeTabs === 2 ? (
            <Buildings />
          ) : (
            <SmartCoil />
          )}
        </div>
      )}
    </div>
  );
};
export default AdminConsole;
