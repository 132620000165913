import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import AuthFooter from "../component/AuthFooter";
import AuthHeader from "../component/AuthHeader";
import CustomHeader from "../component/CustomHeader/CustomHeader";
import Sidebar from "../component/Sidebar";
import { ToastContainer } from "react-toastify";

const CustomRouter = ({ component }) => {
  const [isMobileView, setMobileView] = useState(false);
  const handleSidebar = () => {
    setMobileView(!isMobileView);
  };

  return (
    <div className="layout-wrapper layout-static p-ripple-disabled">
      <CustomHeader handleSidebar={handleSidebar} />
      <Sidebar isMobileView={isMobileView} handleSidebar={handleSidebar} />
      <div className="layout-main-container">
        <div className="layout-main">{component}</div>
      </div>
      <ToastContainer />
      <div className="layout-mask"></div>
    </div>
  );
};
export default CustomRouter;

export const AuthRouter = ({ component }) => {
  return (
    <div
      className="layout-wrapper layout-static p-ripple-disabled"
      style={{
        background:
          "linear-gradient(178deg, #3176A8 19.67%, #3176A8 29.17%, #1F2D52 55.02%)",
      }}
    >
      <AuthHeader />
      <div className="layout-main-container-authheader layout-main-container left-margin">
        <div className="layout-main d-flex justify-content-center align-items-center flex-column">
          {component}
        </div>
        <AuthFooter />
      </div>
    </div>
  );
};

export const AdminRouter = ({ component }) => {
  // const navigate = useNavigate();
  useEffect(() => {
    // onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function onLoad() {
  //   if (
  //     JSON.parse(localStorage.getItem("userDetail"))?.authority !==
  //       "TENANT_ADMIN" &&
  //     JSON.parse(localStorage.getItem("userDetail"))?.authority !== "SYS_ADMIN"
  //   ) {
  //     navigate("/customer/snapshot");
  //   }
  // }
  return (
    <div className="layout-wrapper layout-static p-ripple-disabled">
      <CustomHeader />
      <div className="layout-main-container layout-main-container-pages ml-0">
        <div className="layout-main">{component}</div>
        <AuthFooter isAdmin={true} />
      </div>
    </div>
  );
};

export const HomeRouter = ({ component }) => {
  return (
    <div className="layout-wrapper layout-static p-ripple-disabled">
      <CustomHeader />
      <div className="container-footer-wrapper">
        <div className="container">
          <div className="layout-home-container">{component}</div>
        </div>
        <AuthFooter isAdmin={true} />
      </div>
    </div>
  );
};
