import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/login-logo.png";
import fetchData from "../../../axios/fetchData";
import { login, getUser, tenantInfo, customer } from "../../../axios/serverUrl";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import CustomLoader from "../../../component/Loader/Loader";
import { setLogout } from "../../../redux/actions/coilAction";
import {usePageTracking, userLoggedIn} from "../../../analytics/analytics";

const Login = () => {
  usePageTracking();
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: null,
    password: null,
  });
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  const hidePassword = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 2L22 22"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
          stroke="#777777"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const eyeButton = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7C10.75 7 9.6875 7.4375 8.8125 8.3125C7.9375 9.1875 7.5 10.25 7.5 11.5C7.5 12.75 7.9375 13.8125 8.8125 14.6875C9.6875 15.5625 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.5625 12.8875 9.3 12.25 9.3 11.5C9.3 10.75 9.5625 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3208 5.35 16.9625C3.35 15.6042 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39583 5.35 6.0375C7.35 4.67917 9.56667 4 12 4C14.4333 4 16.65 4.67917 18.65 6.0375C20.65 7.39583 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.6042 18.65 16.9625C16.65 18.3208 14.4333 19 12 19ZM12 17C13.8833 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8833 6 12 6C10.1167 6 8.3875 6.49583 6.8125 7.4875C5.2375 8.47917 4.03333 9.81667 3.2 11.5C4.03333 13.1833 5.2375 14.5208 6.8125 15.5125C8.3875 16.5042 10.1167 17 12 17Z"
          fill="#777777"
        />
      </svg>
    );
  };
  useEffect(() => {
    localStorage.clear();
    setLogout({});
  }, []);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (formData.username !== null && formData.password !== null) {
      setLoading(true);
      const data = await fetchData(
        "POST",
        login,
        {},
        {
          username: formData.username,
          password: formData.password,
        }
      );
      if (data?.token) {
        const decoded = jwtDecode(data.token);
        const userData = await fetchData(
          "GET",
          getUser + "/" + decoded.userId,
          { "X-Authorization": "Bearer " + data.token }
        );
        localStorage.setItem("userDetail", JSON.stringify(userData));
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        if (userData.authority === "TENANT_ADMIN") {
          const tenantInfoData = await fetchData(
            "GET",
            tenantInfo + "/" + userData.tenantId.id,
            { "X-Authorization": "Bearer " + data.token }
          );
          localStorage.setItem("tenantDetail", JSON.stringify(tenantInfoData));
          userLoggedIn();
          navigator("/home");
        } else {
          const customerInfoData = await fetchData(
            "GET",
            customer + "/" + userData.customerId.id,
            { "X-Authorization": "Bearer " + data.token }
          );
          if (customerInfoData?.title) {
            localStorage.setItem(
              "customerDetail",
              JSON.stringify(customerInfoData)
            );
          }
          userLoggedIn();
          navigator("/home");
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.message);
      }
    } else {
      toast.success("All field required!");
      setLoading(false);
    }
  };
  return (
    <div className="col-12 col-md-8 col-lg-12 row d-flex align-items-center">
      <div className="col-12 col-lg-5 bg-white box-shadow-login m-0 login-box">
        <div className="d-flex justify-content-center flex-column login-box-form">
          <h2 className="login-text text-center">Log In</h2>
          <ToastContainer />
          <form onSubmit={onSubmit}>
            <div
              className="mb-3 position-relative input-wrapper"
              onFocus={(event) => {
                event.currentTarget.classList.add("focused");
              }}
              onBlur={(event) => {
                event.currentTarget.classList.remove("focused");
              }}
            >
              <span className="input-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    fill="#BCBCBC"
                    d="M8 1.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2Zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H1.9V13c0-.64 3.13-2.1 6.1-2.1ZM8 0a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4Z"
                  />
                </svg>
              </span>
              <input
                type="email"
                className="form-control"
                placeholder="Username or Email"
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </div>
            <div
              className="mb-3 position-relative input-wrapper"
              onFocus={(event) => {
                event.currentTarget.classList.add("focused");
              }}
              onBlur={(event) => {
                event.currentTarget.classList.remove("focused");
              }}
            >
              <span className="input-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="21"
                  fill="none"
                >
                  <path
                    fill="#BCBCBC"
                    d="M16 7h-3V5.21A5.147 5.147 0 0 0 8.49.02 5.008 5.008 0 0 0 3 5v2H0v14h16V7Zm-8 9a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2ZM5 7V5a3 3 0 1 1 6 0v2H5Z"
                  />
                </svg>
              </span>
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
              <span
                className="toggle-btn input-icon"
                onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? hidePassword() : eyeButton()}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-4 align-items-center">
              <span className="d-flex justify-content-center align-items-center">
                <input
                  type="checkbox"
                  className="checkbox-button__input"
                  id="choice1-1"
                  name="choice1"
                />

                <span className="checkbox-button__control"></span>
                <span className="gray-text mt-1 ml-2">Remember Me</span>
              </span>
              <Link to="/forgot-password" className="ms-2 link-text">
                Forgot Password?
              </Link>
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary-new">
                Log In
              </button>
            </div>
            {/* <div className="d-flex justify-content-center mt-4 flex-column align-items-center d-none">
              <span className="gray-text">Don't have an account?</span>
              <Link to="/register" className="ms-2 link-text">
                Sign Up
              </Link>
            </div> */}
          </form>
        </div>
      </div>
      <div className="d-none d-lg-block col-md-7 m-0 p-0 login-box-image">
        <img
          src={Logo}
          alt="login-logo"
          className="login-logo"
          height={422}
          style={{ marginTop: "-50px" }}
        />
      </div>
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};
export default Login;
