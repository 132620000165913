import { useState } from "react";
import CustomLoader from "../../../../component/Loader/Loader";
import fetchData from "../../../../axios/fetchData";
import { login, tenant, customer } from "../../../../axios/serverUrl";
const AddressInformation = ({ userAddressDetail, userType }) => {
  const [isLoading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState(
    userAddressDetail?.companyName
  );
  const [country, setCountryName] = useState(
    userAddressDetail?.country ? userAddressDetail?.country : null
  );
  const [title, setTitle] = useState(userAddressDetail?.title);
  const [address, setAddress] = useState(userAddressDetail?.address);
  const [address2, setAddress2] = useState(userAddressDetail?.address2);
  const [city, setCity] = useState(userAddressDetail?.city);
  const [userAddress, setUserAddress] = useState({
    country,
    companyName,
    title,
    address,
    address2,
    city,
  });
  const onSubmitInformation = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data;
    if (userType === "tenant") {
      data = await fetchData(
        "POST",
        login,
        {},
        {
          username: process.env.REACT_APP_SYS_ADMIN_USERNAME,
          password: process.env.REACT_APP_SYS_ADMIN_PASSWORD,
        }
      );
      if (data?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
    } else {
      data = await fetchData(
        "POST",
        login,
        {},
        {
          username: process.env.REACT_APP_TENANT_USERNAME,
          password: process.env.REACT_APP_TENANT_PASSWORD,
        }
      );
      if (data?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
    }

    if (data?.token) {
      let systemAdminToken = data.token;

      userAddress.id = userAddressDetail.id;
      const updatedTenantAddress = await fetchData(
        "POST",
        userType === "tenant" ? tenant : customer,
        { "X-Authorization": "Bearer " + systemAdminToken },
        userAddress
      );
      if (updatedTenantAddress?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
      if (userType === "tenant") {
        localStorage.setItem(
          "tenantDetail",
          JSON.stringify(updatedTenantAddress)
        );
      } else {
        localStorage.setItem(
          "customerDetail",
          JSON.stringify(updatedTenantAddress)
        );
      }
      setLoading(false);
    } else if (data?.errorCode === 11) {
      setLoading(false);
      navigator("/login");
      localStorage.clear();
    } else {
      if (userType === "tenant") {
        alert("You have to login as system admin");
      } else {
        alert("You have to login as tenant admin");
      }
      setLoading(false);
    }
  };
  return (
    <form onSubmit={onSubmitInformation}>
      <input
        style={{ display: "none" }}
        className="form-control-normal"
        placeholder="Company Name"
        value={companyName}
        onChange={(e) => {
          setUserAddress({
            ...userAddress,
            companyName: e.target.value,
          });
          setCompanyName(e.target.value);
        }}
      />
      <input
        className="form-control-normal"
        placeholder="country"
        value={country}
        onChange={(e) => {
          setUserAddress({
            ...userAddress,
            country: e.target.value,
          });
          setCountryName(e.target.value);
        }}
      />
      <input
        className="form-control-normal my-3"
        placeholder="Title"
        value={title}
        required
        onChange={(e) => {
          setUserAddress({ ...userAddress, title: e.target.value });
          setTitle(e.target.value);
        }}
      />
      <input
        className="form-control-normal"
        placeholder="Address Line 1"
        value={address}
        onChange={(e) => {
          setUserAddress({ ...userAddress, address: e.target.value });
          setAddress(e.target.value);
        }}
      />
      <input
        className="form-control-normal my-3"
        placeholder="Address Line 2"
        value={address2}
        onChange={(e) => {
          setUserAddress({ ...userAddress, address2: e.target.value });
          setAddress2(e.target.value);
        }}
      />
      <input
        className="form-control-normal"
        placeholder="City"
        value={city}
        onChange={(e) => {
          setUserAddress({ ...userAddress, city: e.target.value });
          setCity(e.target.value);
        }}
      />
      <div className="d-flex justify-content-end my-3">
        <button type="submit" className="btn-update">
          Update
        </button>
      </div>
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </form>
  );
};

export default AddressInformation;
