const howDoingData = [
  {
    id: 1,
    title: "Total Energy Savings",
    percentage: "5",
    value: "60,360",
    valueSymbol: "kWh/year",
    tooltip: "Annual Total Energy Savings for AHU since last cleaning.",
  },
  {
    id: 2,
    title: "Total Cost Savings",
    percentage: "8",
    value: "$7,890.00",
    valueSymbol: "Yearly",
    tooltip: "Annual Total Cost Savings for AHU since last cleaning.",
  },
  {
    id: 3,
    title: "Carbon Offset",
    percentage: "4",
    value: "1,575",
    valueSymbol: "CO2/year",
    tooltip:
      "Annual Total Carbon Emissions reduced from AHU operations since last cleaning.",
  },
];
const PerformanceMetricsData = [
  {
    id: 1,
    title: "Fouling Level",
    value: "80",
  },
  {
    id: 2,
    title: "Months to Next Cleaning",
    value: "2.1",
  },
  {
    id: 3,
    title: "Months Since Last Cleaning",
    value: "0",
  },
  {
    id: 4,
    title: "Grade",
    value: "7.8",
  },
];

export { howDoingData, PerformanceMetricsData };
