import { connect } from "react-redux";
import {getFullLabel, getLabel} from "../../../utils/device";
const MostRecent = ({
  customClass,
  item,
  handleRecentChoice,
  activeList,
  isRecent,
  handleRecentChoiceCancel,
}) => {
  return (
    <button
      className="border-none bg-transparent tag w-100 px-0"
      onClick={() =>
        activeList.length <= 1 && item?.active ? "" : handleRecentChoice(item)
      }
    >
      {!isRecent && (
        <>
          <input
            type="checkbox"
            name="recent-choice"
            className="tag__input"
            checked={item?.active}
            onChange={() =>
              activeList.length <= 1 && item?.active
                ? ""
                : handleRecentChoice(item)
            }
          />
          <label className={customClass} title={getFullLabel(item)}>{getLabel(item)}</label>
        </>
      )}

      {isRecent && (
          <div className="d-flex justify-content-between align-items-center w-100">
            <label className={"tag_recent_label ps-0"} title={getFullLabel(item)}>{getLabel(item)}</label>
            <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                fill="black"
              />
            </svg>
          </span>
          </div>
      )}
    </button>
  );
};
const mapStateToProps = (state) => ({
  activeList: state.coilList.activeList,
});
export default connect(mapStateToProps, {})(MostRecent);
