import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import LayerImage from "../../../../assets/images/Layer.png";
import fetchData from "../../../../axios/fetchData";
import { telemetry } from "../../../../axios/serverUrl";
import SmartCoilImage from "../../../../component/SmartCoilImage/SmartCoilImage";
import Spinner from "../../../../component/Spinner/Spinner";
import {
  activeCoil,
  activeSnapCoil,
  inActiveCoil,
} from "../../../../redux/actions/coilAction";
import {getFullLabel, getLabel, getSmartCoilColor} from "../../../../utils/device";
import {getSharedAttributes} from "../../../../axios/deviceApi";
const Grid = ({
  item,
  index,
  isGridView,
  relationList,
  activeSnapCoil,
  activeList,
  activeCoil,
  inActiveCoil,
}) => {
  const [imageColor, setColor] = useState("");
  useEffect(() => {
    const currentCoilId = item.id.id;
    const coilIdsForCurrentBuilding = relationList
      ?.filter((item) => item?.CoilId === currentCoilId)
      ?.map((item) => item);
    // eslint-disable-next-line
  }, [relationList]);
  const fetchImage = async () => {
    const data = await getSharedAttributes(item);

    if (data?.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    }
    setColor(getSmartCoilColor(data?.flvl));
  };
  useEffect(() => {
    if (item?.deviceProfileId) {
      fetchImage();
    }
    // eslint-disable-next-line
  }, [item?.deviceProfileId]);
  const navigator = useNavigate();
  const redirectListingPage = (entityType, id) => {
    if (entityType === "ASSET") {
      navigator(`/home/coils?BuildingId=${id}`);
    } else if (entityType === "DEVICE") {
      const hasRelation = relationList.some((item) => item.CoilId === id);
      if (hasRelation) {
        if (activeList.length < 3) {
          const activeCoilId = {};
          activeCoilId.entityType = "DEVICE";
          activeCoilId.id = id;
          activeCoil(activeCoilId);
          activeSnapCoil(activeCoilId);
          navigator(`/customer/snapshot`);
        } else {
          const lastIndex = activeList[activeList.length - 1];
          if (activeList.findIndex((item) => item.id.id === id) === -1) {
            inActiveCoil(lastIndex.id);
          }
          const activeCoilId = {};
          activeCoilId.entityType = "DEVICE";
          activeCoilId.id = id;
          activeCoil(activeCoilId);
          activeSnapCoil(activeCoilId);
          navigator(`/customer/snapshot`);
        }
      }
    }
  };

  return (
    <div
      key={index}
      className={isGridView ? "grid-product-container" : "col-12 col-md-6"}
    >
      <figure
        className="home-container mt-2 pr-2 pl-2"
        onClick={() => {
          redirectListingPage(item.id.entityType, item.id.id);
        }}
      >
        <div className="home">
          {item.id.entityType === "ASSET" ? (
              <>
                <div className="home-img-wrapper home-img-building-wrapper">
                  <img
                      className={"home-hero-img-building"}
                      src={LayerImage}
                      alt="BuildingImage"
                  />
                </div>
                <div className="home-heading-container">
                  <div className="home-title-container">
                    <h3 className="grid-title">{item.name}</h3>
                  </div>
                </div>
              </>
          ) : (
              <>
                <div className="home-img-wrapper home-img-coil-wrapper">
                  {imageColor ? (
                      <div className="home-coil-image">
                        <SmartCoilImage color={imageColor}/>
                      </div>
                  ) : (
                      <Spinner color="#fff" padding={0}/>
                  )}
                </div>
                <div className="home-heading-container">
                  <div className="home-title-container">
                    <h3 className="grid-title" title={getFullLabel(item)}>{getLabel(item)}</h3>
                  </div>
                </div>
              </>
          )}
        </div>
      </figure>
    </div>
  );
};

const mapStateToProps = (state) => ({
  relationList: state.buildingList.relationList,
  activeList: state.coilList.activeList,
});

export default connect(mapStateToProps, {
  activeSnapCoil,
  inActiveCoil,
  activeCoil,
})(Grid);
