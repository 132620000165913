import axios from "axios";

const fetchData = async (method, url, header, body) => {
  return await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      ...header,
    },
    data: body,
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log("error", error?.response);
      if (
        error?.response?.data?.status === 401 ||
        error?.response?.data?.errorCode === 11
      ) {
        // navigator("/login");
        localStorage.clear();
      }
      return error?.response?.data;
    });
};

export default fetchData;
