import lineChartDataOption from "./RawDataLineChartData";
import RenderChart from "../../../component/RenderData/RenderChart";
import {
  dropdownOptionsBio,
  dropdownOptionsRaw,
  dropdownOptionsBioKey,
} from "../../../utils/dataPoints";
import {usePageTracking} from "../../../analytics/analytics";
function RawData() {
  usePageTracking();
  return (
    <RenderChart
      chartOptions={lineChartDataOption}
      chartTitle="Raw Data"
      chartSubTitle="COIL RAW DATA LEVEL"
      dropdownOptions={dropdownOptionsRaw}
      dropdownOptionsBio={dropdownOptionsBio}
      dropdownOptionsBioKey={dropdownOptionsBioKey}
    />
  );
}
export default RawData;
