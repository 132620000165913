const formattedDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")} ${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}`;
};

const formattedDateString = (timestamp) => {
  const currentDate = new Date();
  const date = new Date(timestamp);
  const timeDifference = currentDate - date;

  const secondsInMilli = 1000;
  const minutesInMilli = secondsInMilli * 60;
  const hoursInMilli = minutesInMilli * 60;
  const daysInMilli = hoursInMilli * 24;

  const daysAgo = Math.floor(timeDifference / daysInMilli);
  if (daysAgo === -1) {
    return `Today`;
  } else {
    return `${daysAgo} days ago`;
  }
};

export { formattedDate, formattedDateString };
