import { Tooltip as ReactTooltip } from "react-tooltip";

const PerformanceMetrics = ({ coilData }) => {
  return (
    <div className="bg-gray-color performance-metrics">
      <h3>Performance Metrics</h3>

      <>
        <div className="fancy-number">
          <div>Coil Fouling Level</div>
          <span
            className="info-icon mb-1 ml-3"
            type="button"
            data-tooltip-id={"my-tooltip-fouling"}
          >
            <span className="p-button-icon pi pi-info-circle"></span>
            {/* <span className="p-button-label">&nbsp;</span>
                  <span className="p-ink"></span> */}
          </span>
          <ReactTooltip
            id="my-tooltip-fouling"
            place="bottom"
            style={{
              zIndex: 9999,
              width: "200px",
              background: "black",
              marginTop: "-10px",
            }}
            content="Indicates the current fouling level of a coil where zero represents a recently cleaned coil and 100 or higher represents a coil requiring cleaning. Higher fouling levels indicate reduced coil performance and increased energy consumption as compared to a clean coil."
          />
          <div>
            {coilData?.fouling_level >= 0 ? coilData?.fouling_level : undefined}
          </div>
        </div>
        <div className="fancy-number">
          <div>Months to Next Cleaning</div>
          <span
            className="info-icon mb-1 ml-3"
            type="button"
            data-tooltip-id={"my-tooltip-next-cleaning"}
          >
            <span className="p-button-icon pi pi-info-circle"></span>
            {/* <span className="p-button-label">&nbsp;</span>
                  <span className="p-ink"></span> */}
          </span>
          <ReactTooltip
            id="my-tooltip-next-cleaning"
            place="bottom"
            style={{
              zIndex: 9999,
              width: "200px",
              background: "black",
              marginTop: "-10px",
            }}
            content="Represents the recommended coil cleaning time frame to minimize overall operating and maintenance costs."
          />
          <div>
            {coilData?.months_to_next_cleaning}
          </div>
        </div>
        <div className="fancy-number">
          <div>Months Since Last Cleaning</div>
          <span
            className="info-icon mb-1 ml-3"
            type="button"
            data-tooltip-id={"my-tooltip-last-cleaning"}
          >
            <span className="p-button-icon pi pi-info-circle"></span>
            {/* <span className="p-button-label">&nbsp;</span>
                  <span className="p-ink"></span> */}
          </span>
          <ReactTooltip
            id="my-tooltip-last-cleaning"
            place="bottom"
            style={{
              zIndex: 9999,
              width: "200px",
              background: "black",
              marginTop: "-10px",
            }}
            content="Tracks the elapsed time, in months, since the last coil cleaning."
          />
          <div>
            {coilData?.months_since_last_cleaning}
          </div>
        </div>
        <div className="fancy-number">
          <div>Grade</div>
          <span className="info-icon mb-1 ml-3" type="button">
            {/* <span className="p-button-icon pi pi-info-circle"></span> */}
            {/* <span className="p-button-label">&nbsp;</span>
                  <span className="p-ink"></span> */}
          </span>
          <div>{coilData?.grade}</div>
        </div>
      </>
    </div>
  );
};
export default PerformanceMetrics;
