import React from "react";
import { Link } from "react-router-dom";
import Gmail from "../../../assets/images/gmail.png";
import Outlook from "../../../assets/images/outlook.png";

const CheckEmail = () => {
  return (
    <div className="col-md-12 row d-flex justify-content-center align-items-center">
      <div className="col-md-5 bg-white box-shadow-login m-0">
        <div className="d-flex justify-content-start align-items-start">
          <h2 className="login-text ">Check Your Email</h2>
        </div>
        <div className="mb-5">
          <h6 className="gray-text">
            We’ve sent a message to
            <br /> beth@mlbcreative.com with a link to activate your account.
          </h6>
          <div className="d-flex mb-5 mt-3">
            <div className="d-flex justify-content-center align-items-center">
              <img src={Gmail} alt="gmail" />
              <h6 className="link-text text-decoration-underline ms-2">
                Open Gmail
              </h6>
            </div>
            <div className="d-flex justify-content-center align-items-center ms-4">
              <img src={Outlook} alt="outlook" />
              <h6 className="link-text text-decoration-underline  ms-2">
                Open Outlook
              </h6>
            </div>
          </div>
          <h6 className="gray-text-bold">
            Didn’t receive an email? Check your spam folder.
          </h6>
          <Link to="/">
            <h6 className="link-text text-decoration-underline">
              Re-enter your email and try again
            </h6>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CheckEmail;
