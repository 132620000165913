import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const initAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    setUserContext();
}

function setUserContext() {
    const userId = JSON.parse(localStorage.getItem("userDetail"))?.id?.id;
    const customerId = JSON.parse(localStorage.getItem("customerDetail"))?.id?.id;
    ReactGA.gtag('set','user_properties', {userId, customerId});
}

export const userLoggedIn = () => {
    setUserContext();
}

export const userLoggedOut = () => {
    ReactGA.gtag('set','user_properties', { userId: undefined, customerId: undefined });
}

export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
};
