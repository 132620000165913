import { Modal } from "react-bootstrap";
import Loader from "react-js-loader";

const CustomLoader = ({ idLoading, handleLoading }) => {
  return (
    <Modal show={idLoading} onHide={handleLoading} className="bg-transparent">
      <Modal.Body className="bg-transparent">
        <Loader type="bubble-loop" bgColor={"#025173"} size={100} />
      </Modal.Body>
    </Modal>
  );
};
export default CustomLoader;
