// import getRandomColor from "../../utils/common";
import {
  ADD_COIL,
  REMOVE_COIL,
  UPDATE_COIL,
  ACTIVE_COIL,
  ACTIVE_SNAP_COIL,
  ACTIVE_LIST_COIL,
  RESET_ACTION,
  REMOVE_RECENT_COIL,
  REMOVE_RECENT_SNAP_COIL,
  INACTIVE_COIL,
} from "../constant";

const initialState = {
  coilList: [],
  activeList: [],
  recentList: [],
  recentSnapList: [],
};

const CoilReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COIL: {
      return {
        ...state,
        coilList: [
          ...action.payload.map((item, index) => {
            return {
              ...item,
              active: false,
              // color: getRandomColor(index),
              updatedAt: new Date(),
            };
          }),
        ],
      };
    }
    case ACTIVE_LIST_COIL: {
      return {
        ...state,
        activeList: [...state.coilList.filter((item) => item.active === true)],
        recentList: [...state.recentList],
        recentSnapList: [...state.recentSnapList],
      };
    }
    case REMOVE_COIL: {
      return {
        ...state,
        coilList: state.coilList.filter((coil) => {
          return coil?.id?.id !== action?.payload?.id;
        }),
      };
    }
    case REMOVE_RECENT_SNAP_COIL: {
      return {
        ...state,
        recentSnapList:
          state.recentSnapList.length !== 1
            ? state.recentSnapList.filter((coil) => {
                return coil?.id?.id !== action?.payload?.id;
              })
            : state.recentSnapList,
      };
    }
    case REMOVE_RECENT_COIL: {
      return {
        ...state,
        recentList:
          state.recentList.length !== 1
            ? state.recentList.filter((coil) => {
                return coil?.id?.id !== action?.payload?.id;
              })
            : state.recentList,
      };
    }
    case ACTIVE_COIL: {
      return {
        ...state,
        coilList: state.coilList.map((coil) => {
          return coil.id.id === action.payload.id
            ? Object.assign({}, coil, {
                active: !coil.active,
                color:
                  state.coilList.findIndex((v) => v.active === true) === -1
                    ? "#4CAF50"
                    : ["#4CAF50", "#2196F3", "#ff6e40"].filter(
                        (m) =>
                          !state.coilList
                            .filter((v) => v.active === true)
                            .map((i) => i.color)
                            .includes(m)
                      )[0] || "#4CAF50",
                updatedAt: new Date(),
              })
            : coil;
        }),
        activeList: [
          ...state.coilList
            .map((coil) => {
              return coil.id.id === action.payload.id
                ? Object.assign({}, coil, {
                    active: !coil.active,
                    color:
                      state.coilList.findIndex((v) => v.active === true) === -1
                        ? "#4CAF50"
                        : ["#4CAF50", "#2196F3", "#ff6e40"].filter(
                            (m) =>
                              !state.coilList
                                .filter((v) => v.active === true)
                                .map((i) => i.color)
                                .includes(m)
                          )[0] || "#4CAF50",
                    updatedAt: new Date(),
                  })
                : coil;
            })
            .filter((item) => item.active === true)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
        ],
        recentList: state.recentList.find(
          (coil) => coil.id.id === action.payload.id
        )
          ? [
              ...state.recentList.map((coil) => {
                return coil.id.id === action.payload.id
                  ? Object.assign({}, coil, {
                      active: !coil.active,
                    })
                  : coil;
              }),
            ]
          : [
              ...state.recentList.filter(
                (coil) => coil.id.id !== action.payload.id
              ),
              ...state.coilList
                .filter((coil) => coil.id.id === action.payload.id)
                .map((item) => {
                  return {
                    ...item,
                    active: !item.active,
                    updatedAt: new Date(),
                  };
                }),
            ],
      };
    }
    case INACTIVE_COIL: {
      return {
        ...state,
        coilList: state.coilList.map((coil) => {
          return coil.id.id === action.payload.id
            ? Object.assign({}, coil, {
                active: false,
                updatedAt: new Date(),
              })
            : coil;
        }),
        activeList: [
          ...state.coilList
            .map((coil) => {
              return coil.id.id === action.payload.id
                ? Object.assign({}, coil, {
                    active: false,
                    updatedAt: new Date(),
                  })
                : coil;
            })
            .filter((item) => item.active === true)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
        ],
        recentList: state.recentList.find(
          (coil) => coil.id.id === action.payload.id
        )
          ? [
              ...state.recentList.map((coil) => {
                return coil.id.id === action.payload.id
                  ? Object.assign({}, coil, {
                      active: false,
                    })
                  : coil;
              }),
            ]
          : [
              ...state.recentList.filter(
                (coil) => coil.id.id !== action.payload.id
              ),
              ...state.coilList
                .filter((coil) => coil.id.id === action.payload.id)
                .map((item) => {
                  return {
                    ...item,
                    active: false,
                    updatedAt: new Date(),
                  };
                }),
            ],
      };
    }
    case ACTIVE_SNAP_COIL: {
      return {
        ...state,
        activeSnapList: state.coilList.map((coil) => {
          return coil.id.id === action.payload.id
            ? Object.assign({}, coil, {
                active: true,
                updatedAt: new Date(),
              })
            : Object.assign({}, coil, {
                active: false,
              });
        }),
        recentSnapList: state.recentSnapList.find(
          (coil) => coil.id.id === action.payload.id
        )
          ? [
              ...state.recentSnapList.map((coil) => {
                return coil.id.id === action.payload.id
                  ? Object.assign({}, coil, {
                      active: true,
                      updatedAt: new Date(),
                    })
                  : Object.assign({}, coil, {
                      active: false,
                    });
              }),
            ]
          : [
              ...state.recentSnapList
                .filter((coil) => coil.id.id !== action.payload.id)
                .map((item) => {
                  return {
                    ...item,
                    active: false,
                  };
                }),
              ...state.coilList
                .filter((coil) => coil.id.id === action.payload.id)
                .map((item) => {
                  return {
                    ...item,
                    active: true,
                    updatedAt: new Date(),
                  };
                }),
            ],
      };
    }
    case UPDATE_COIL: {
      return {
        ...state,
        coilList: state.coilList.map((coil) => {
          if (coil.id === action.payload.id) {
            return {
              ...coil,
              coil: action.payload.coil,
            };
          }
          return coil;
        }),
      };
    }
    case RESET_ACTION: {
      return initialState;
    }

    default:
      return state;
  }
};
export default CoilReducer;
