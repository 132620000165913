import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import fetchData from "../../../axios/fetchData";
import { resetPasswordByEmail } from "../../../axios/serverUrl";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: null,
  });
  const onSubmit = async (event) => {
    event.preventDefault();
    if (formData.email !== null) {
      const data = await fetchData(
        "POST",
        resetPasswordByEmail,
        {},
        {
          email: formData.email,
        }
      );
      if (data?.errorCode === 11) {
        navigator("/login");
        localStorage.clear();
      }
      if (data?.token) {
      } else {
        toast.error(data?.message);
      }
    } else {
    }
  };
  return (
    <div className="col-12 col-md-8 col-lg-12 row  d-flex justify-content-center">
      <div className="col-md-5 bg-white box-shadow-login m-0">
        <div className="d-flex justify-content-center align-items-center mb-3">
          <h3>Forgot Password</h3>
        </div>
        <form onSubmit={onSubmit}>
          <div
            className="mb-3 position-relative input-wrapper"
            onFocus={(event) => {
              event.currentTarget.classList.add("focused");
            }}
            onBlur={(event) => {
              event.currentTarget.classList.remove("focused");
            }}
          >
            {/* <span className="input-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M22 4.45947H2.01L2 20.6757H22V4.45947ZM20 18.6487H4V8.51353L12 13.5811L20 8.51353V18.6487ZM12 11.5541L4 6.4865H20L12 11.5541Z"
                  fill="#BCBCBC"
                />
              </svg>
            </span> */}
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary-new">
              Submit
            </button>
          </div>
          {/* <div className="d-flex justify-content-center mt-4 flex-column align-items-center">
            <span className="gray-text">Don't have an account?</span>
            <Link to="/register" className="ms-2 link-text">
              Sign Up
            </Link>
          </div> */}
        </form>
      </div>{" "}
      <ToastContainer />
    </div>
  );
};
export default ForgotPassword;
