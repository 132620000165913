import { connect } from "react-redux";
import { toast } from "react-toastify";
import {getFullLabel, getLabel} from "../../../utils/device";
const RecentChoice = ({
  customClass,
  item,
  handleRecentChoice,
  activeList,
}) => {
  // const [disable, setDisable] = useState(false);
  // useEffect(() => {
  //   if (activeList.length >= 3) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [activeList.length]);

  return (
    <button
      // disabled={disable && !item?.active}
      // style={{ pointerEvents: disable && !item?.active ? "none" : "" }}
      className="border-none bg-transparent tag px-0 mb-2"
      onClick={() =>
        activeList.length < 2 && item?.active
          ? ""
          : activeList.length >= 3 && !item?.active
          ? toast.error(
              "Maximum comparison limit reached. Please choose up to 3 Gateways to compare."
            )
          : handleRecentChoice(item)
      }
    >
      <input
        type="checkbox"
        name="recent-choice"
        className="tag__input"
        checked={item?.active}
        onChange={() =>
          activeList.length < 2 && item?.active
            ? ""
            : activeList.length >= 3 && !item?.active
            ? toast.error(
                "Maximum comparison limit reached. Please choose up to 3 Gateways to compare."
              )
            : handleRecentChoice(item)
        }
      />
      <label className={customClass} title={getFullLabel(item)}>{getLabel(item)}</label>
    </button>
  );
};
const mapStateToProps = (state) => ({
  activeList: state.coilList.activeList,
});
export default connect(mapStateToProps, {})(RecentChoice);
