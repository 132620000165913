import getRandomColor from "../../utils/common";
import {
  ADD_BUILDING,
  ADD_RELATION,
  REMOVE_BUILDING,
  UPDATE_BUILDING,
  ACTIVE_BUILDING,
  ACTIVE_LIST_BUILDING,
  RESET_ACTION,
} from "../constant";

const initialState = {
  buildingList: [],
  activeList: [],
  recentList: [],
  relationList: [],
};

const BuildingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUILDING: {
      return {
        ...state,
        buildingList: [
          ...action.payload.map((item, index) => {
            return { ...item, active: false, color: getRandomColor(index) };
          }),
        ],
      };
    }
    case ADD_RELATION: {
      return {
        ...state,
        relationList: [
          ...action.payload.map((item, index) => {
            return { ...item };
          }),
        ],
      };
    }
    case ACTIVE_LIST_BUILDING: {
      return {
        ...state,
        activeList: [
          ...state.buildingList.filter((item) => item.active === true),
        ],
        recentList: [...state.recentList],
      };
    }
    case REMOVE_BUILDING: {
      return {
        ...state,
        buildingList: state.buildingList.filter((building) => {
          return building.id !== action.payload.id;
        }),
      };
    }
    case ACTIVE_BUILDING: {
      return {
        ...state,
        buildingList: state.buildingList.map((building) => {
          return building.id.id === action.payload.id
            ? Object.assign({}, building, {
                active: !building.active,
              })
            : building;
        }),
        activeList: [
          ...state.buildingList
            .map((building) => {
              return building.id.id === action.payload.id
                ? Object.assign({}, building, {
                    active: !building.active,
                  })
                : building;
            })
            .filter((item) => item.active === true),
        ],
        recentList: state.recentList.find(
          (building) => building.id.id === action.payload.id
        )
          ? [
              ...state.recentList.map((building) => {
                return building.id.id === action.payload.id
                  ? Object.assign({}, building, {
                      active: !building.active,
                    })
                  : building;
              }),
            ]
          : [
              ...state.recentList.filter(
                (building) => building.id.id !== action.payload.id
              ),
              ...state.buildingList
                .filter((building) => building.id.id === action.payload.id)
                .map((item) => {
                  return {
                    ...item,
                    active: !item.active,
                  };
                }),
            ],
      };
    }
    case UPDATE_BUILDING: {
      return {
        ...state,
        buildingList: state.buildingList.map((building) => {
          if (building.id === action.payload.id) {
            return {
              ...building,
              building: action.payload.building,
            };
          }
          return building;
        }),
      };
    }
    case RESET_ACTION: {
      return initialState;
    }

    default:
      return state;
  }
};
export default BuildingReducer;
