import React, { useState } from "react";
import Grid from "./Grid/Grid";

const PageListing = ({ listData, toggleView }) => {
  const imagePerRow = 5;
  const [next, setNext] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  return (
    <>
      <div className={toggleView ? "custom-row" : "row"}>
        {toggleView ? (
          <>
            {listData?.slice(0, next)?.map((item, index) => (
              <Grid item={item} index={index} isGridView={true} />
            ))}
          </>
        ) : (
          <div className={toggleView ? "list-view col-12" : "list-view active"}>
            {listData?.slice(0, next)?.map((item, index) => (
              <Grid item={item} index={index} isGridView={false} />
            ))}
          </div>
        )}
      </div>
      {next < listData?.length && (
        <button
          type="button"
          className="btn load-more mt-4"
          onClick={handleMoreImage}
        >
          Load More
        </button>
      )}
    </>
  );
};

export default PageListing;
