import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import fetchData from "../../../axios/fetchData";
import { login, register } from "../../../axios/serverUrl";

const Register = () => {
  const navigator = useNavigate();
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    authority: "CUSTOMER_USER",
    email: null,
  });
  const onSubmit = async (event) => {
    event.preventDefault();
    if (
      formData.firstName !== null &&
      formData.lastName !== null &&
      formData.authority.length > 0 &&
      formData.email !== null
    ) {
      const loginData = await fetchData(
        "POST",
        login,
        {},
        {
          username: "alan@flooddigital.co",
          password: "$C6k6mt%QV",
        }
      );
      if (loginData?.token) {
        const body = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          authority: formData.authority,
          email: formData.email,
          tenantId: {
            entityType: "TENANT",
            id: "5b2bb360-08a8-11ea-a163-53ea4ca0567c",
          },
          customerId: {
            entityType: "CUSTOMER",
            id: "36414f90-614d-11ee-885d-45c50854d01d",
          },
        };
        const data = await fetchData(
          "POST",
          register,
          { "X-Authorization": "Bearer " + loginData.token },
          body
        );
        if (data?.id) {
          toast.success(formData.authority + " created successfully!");
          navigator("/login");
        } else {
          toast.error(data?.message);
        }
      }
    } else {
    }
  };

  return (
    <div className="col-12 col-md-8 col-lg-12 row  d-flex justify-content-center">
      <div className="col-md-5 bg-white box-shadow-login m-0">
        <div className="d-flex justify-content-center align-items-center">
          <h2 className="login-text">Register</h2>
        </div>
        <form onSubmit={onSubmit}>
          <div
            className="mb-3 position-relative input-wrapper"
            onFocus={(event) => {
              event.currentTarget.classList.add("focused");
            }}
            onBlur={(event) => {
              event.currentTarget.classList.remove("focused");
            }}
          >
            <span className="input-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
              >
                <path
                  fill="#BCBCBC"
                  d="M8 1.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2Zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H1.9V13c0-.64 3.13-2.1 6.1-2.1ZM8 0a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4Z"
                />
              </svg>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Create a Username"
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
            />
          </div>
          <div
            className="mb-3 position-relative input-wrapper"
            onFocus={(event) => {
              event.currentTarget.classList.add("focused");
            }}
            onBlur={(event) => {
              event.currentTarget.classList.remove("focused");
            }}
          >
            <span className="input-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="21"
                fill="none"
              >
                <path
                  fill="#BCBCBC"
                  d="M16 7h-3V5.21A5.147 5.147 0 0 0 8.49.02 5.008 5.008 0 0 0 3 5v2H0v14h16V7Zm-8 9a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2ZM5 7V5a3 3 0 1 1 6 0v2H5Z"
                />
              </svg>
            </span>
            <input
              type="password"
              className="form-control"
              placeholder="Create a Password"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </div>
          <div
            className="mb-3 position-relative input-wrapper"
            onFocus={(event) => {
              event.currentTarget.classList.add("focused");
            }}
            onBlur={(event) => {
              event.currentTarget.classList.remove("focused");
            }}
          >
            <span className="input-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M22 4.45947H2.01L2 20.6757H22V4.45947ZM20 18.6487H4V8.51353L12 13.5811L20 8.51353V18.6487ZM12 11.5541L4 6.4865H20L12 11.5541Z"
                  fill="#BCBCBC"
                />
              </svg>
            </span>
            <input
              type="email"
              className="form-control"
              placeholder="Your Email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className="d-flex mb-4 align-items-center">
            <span className="d-flex justify-content-center align-items-center">
              <input
                type="checkbox"
                className="checkbox-button__input"
                id="choice1-2"
                name="choice2"
              />
              <span className="checkbox-button__control"></span>
              <span className="gray-text mt-1 pl-2">
                By creating an account you agree to our Terms of Use and Privacy
                Policy.
              </span>
            </span>
          </div>
          <div className="d-grid mt-3">
            <button type="submit" className="btn btn-primary-new">
              Submit
            </button>
          </div>
          <div className="d-flex justify-content-center mt-4 flex-column align-items-center">
            <span className="gray-text">Already have an account?</span>
            <Link to="/login" className="ms-2 link-text">
              Log In
            </Link>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Register;
