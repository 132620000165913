import { useContext, useEffect } from "react";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  useEffect(() => {
    decoratedOnClick();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {!isCurrentEventKey ? (
        <button
          onClick={decoratedOnClick}
          className="border-none bg-transparent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M5.16663 6.83342H0.166626V5.16675H5.16663V0.166748H6.83329V5.16675H11.8333V6.83342H6.83329V11.8334H5.16663V6.83342Z"
              fill="black"
            />
          </svg>
        </button>
      ) : (
        <button
          onClick={decoratedOnClick}
          className="border-none bg-transparent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
          >
            <path d="M0 1.42857V0H10V1.42857H0Z" fill="black" />
          </svg>
        </button>
      )}
    </>
  );
}
export default ContextAwareToggle;
