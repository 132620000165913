const mainUrl = process.env.REACT_APP_API_URL;

const authUrl = "/auth";
const noAuthUrl = "/noauth";
const uoauthUrl = "/uoauth";
const customerUrl = "/customer";
const pluginUrl = "/plugins";
const tenantUrl = "/tenant";
const asset = "/asset";
const device = "/device";
const relation = "/relations";

const register = mainUrl + "/user?sendActivationMail=false";
const activateAccount =
  mainUrl + noAuthUrl + "/activate?sendActivationMail=false";
const login = mainUrl + authUrl + "/login";
const logout = mainUrl + authUrl + "/logout";
const resetPasswordByEmail = mainUrl + uoauthUrl + "/resetPasswordByEmail";
const customer = mainUrl + customerUrl;
const telemetry = mainUrl + pluginUrl + "/telemetry";
const customers = mainUrl + "/customers";
const buildings = mainUrl + tenantUrl + "/assetInfos";
const coilGateways = mainUrl + tenantUrl + "/deviceInfos";
const assets = mainUrl + asset + "/info";
const tenant = mainUrl + tenantUrl;
const authUrlt = mainUrl + tenantUrl;
const getUser = mainUrl + "/user";
const chnagePassword = mainUrl + authUrl + "/changePassword";
const chnagePasswordWithNoAuth = mainUrl + noAuthUrl + "/changePassword";
const tenantInfo = mainUrl + tenantUrl + "/info";
// const tenantUrlList = mainUrl + tenantUrl + "/assetInfos";
const relationInfo = mainUrl + relation + "/info";
const devices = mainUrl + device;
// const tenantList = mainUrl + tenant + "/assetInfos";

export {
  login,
  logout,
  resetPasswordByEmail,
  customer,
  telemetry,
  register,
  customers,
  tenantUrl,
  assets,
  tenant,
  // tenantUrlList,
  authUrlt,
  coilGateways,
  getUser,
  chnagePassword,
  tenantInfo,
  relationInfo,
  // tenantList,
  buildings,
  devices,
  activateAccount,
  chnagePasswordWithNoAuth,
};
