import React from "react";
import Slider from "react-rangeslider";
// To include the default styles
import "react-rangeslider/lib/index.css";

const StackedProgressBars = ({ selectedValue, barValue }) => {
  // const [rangeValue, setRangeValue] = useState(2.1);

  // const handleChange = (value) => {
  //   setRangeValue(value.toFixed(1));
  // };
  const handleChangeStart = () => {
    console.log("Change event started");
  };
  const handleChangeComplete = () => {
    console.log("Change event completed");
  };
  return (
    <div className="slider mt-3">
      <div className="big-number mt-0">
        {selectedValue == null ? "--" : Math.round(selectedValue)}
      </div>
      <div id="progress" className="progress">
        <div className="progress-bar bg-color1" style={{ width: "50%" }}></div>
        <div className="progress-bar bg-color2" style={{ width: "20%" }}></div>
        <div className="progress-bar bg-color3" style={{ width: "20%" }}></div>
        <div className="progress-bar bg-color4" style={{ width: "10%" }}></div>
      </div>
      <Slider
        reverse={false}
        min={0}
        max={100}
        step={0.1}
        width={"24px"}
        value={barValue === null?0:barValue>=90?100:barValue}
        tooltip={false}
        onChangeStart={handleChangeStart}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
};
export default StackedProgressBars;
