import { Link, useLocation } from "react-router-dom";

const SubHeader = () => {
  const location = useLocation();

  return (
    <div className="d-flex mr-4">
      <div className="d-flex flex-wrap align-items-center mr-5">
        <div className="menu-title" style={{color: "#025173"}}>
          DASHBOARDS
        </div>
        <div className="header-line"></div>
        <Link
            to="/customer/snapshot"
            className="menu-item snapshot-item"
            style={{
              cursor: "pointer",
              color:
                  (location.pathname === "/customer/snapshot" ||
                      location.pathname === "/") &&
                  "#377ABF",
            }}
        >
          {location.pathname === "/customer/snapshot" ||
          location.pathname === "/" ? (
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
              >
                <path
                    d="M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.66667 10.6667V6.66667H1.33333V10.6667H4.66667ZM6 10.6667H10.6667V6.66667H6V10.6667ZM1.33333 5.33333H10.6667V1.33333H1.33333V5.33333Z"
                    fill="#377ABF"
                />
              </svg>
          ) : (
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
              >
                <path
                    d="M1.33333 12C0.966667 12 0.652778 11.8694 0.391667 11.6083C0.130556 11.3472 0 11.0333 0 10.6667V1.33333C0 0.966667 0.130556 0.652778 0.391667 0.391667C0.652778 0.130556 0.966667 0 1.33333 0H10.6667C11.0333 0 11.3472 0.130556 11.6083 0.391667C11.8694 0.652778 12 0.966667 12 1.33333V10.6667C12 11.0333 11.8694 11.3472 11.6083 11.6083C11.3472 11.8694 11.0333 12 10.6667 12H1.33333ZM4.66667 10.6667V6.66667H1.33333V10.6667H4.66667ZM6 10.6667H10.6667V6.66667H6V10.6667ZM1.33333 5.33333H10.6667V1.33333H1.33333V5.33333Z"
                    fill="#39393B"
                />
              </svg>
          )}
          <span>Snapshot</span>
        </Link>
        <Link
            to="/customer/fouling"
            className={
                "menu-item px-0 " +
                (location.pathname === "/customer/fouling" && " menu-item-active")
            }
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
          >
            <path
                d="M2.3335 14.0859L1.58098 13.3334L6.3335 8.5809L8.87642 11.1238L9.00776 11.2552L9.13105 11.1162L13.7411 5.92183L14.4266 6.60737L8.99241 12.7448L6.45724 10.2097L6.3335 10.0859L6.20975 10.2097L2.3335 14.0859ZM2.3335 10.0859L1.58098 9.33342L6.3335 4.5809L8.87642 7.12383L9.00776 7.25516L9.13105 7.11624L13.7411 1.92183L14.4266 2.60737L8.99241 8.74485L6.45724 6.20967L6.3335 6.08593L6.20975 6.20967L2.3335 10.0859Z"
                fill="#707C82"
                stroke="#707C82"
                strokeWidth="0.35"
            />
          </svg>
          Fouling
        </Link>
        <Link
            to="/customer/performance"
            className={
                "menu-item pr-0 " +
                (location.pathname === "/customer/performance" &&
                    " menu-item-active")
            }
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
          >
            <path
                d="M9 12V6.75H12V12H9ZM4.5 12V0H7.5V12H4.5ZM0 12V3.75H3V12H0Z"
                fill="#707C82"
            />
          </svg>
          Performance
        </Link>
        <Link
            pointerenent="none"
            to="/customer/raw-data"
            className={
                "menu-item pr-0 " +
                (location.pathname === "/customer/raw-data" && " menu-item-active")
            }
        >
          <div className="raw-data-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
            >
              <path
                  d="M2.8335 14.1666V12.0416H5.66683V14.1666H2.8335ZM2.8335 11.3333V8.49992H5.66683V11.3333H2.8335ZM2.8335 7.79159V2.83325H5.66683V7.79159H2.8335ZM7.0835 14.1666V9.20825H9.91683V14.1666H7.0835ZM7.0835 8.49992V5.66659H9.91683V8.49992H7.0835ZM7.0835 4.95825V2.83325H9.91683V4.95825H7.0835ZM11.3335 14.1666V12.7499H14.1668V14.1666H11.3335ZM11.3335 12.0416V9.20825H14.1668V12.0416H11.3335ZM11.3335 8.49992V2.83325H14.1668V8.49992H11.3335Z"
                  fill="#707C82"
                  stroke="none"
              />
            </svg>
          </div>
          Raw Data
        </Link>
        <div className="header-line" style={{marginLeft: '10px'}}></div>
        <Link
            target="_blank"
            to={"https://backend-prod.sensiblehvac.io/export/?token=" + btoa(localStorage.getItem("token"))}
            className={"menu-item pr-0"}
        >
          Export
        </Link>
      </div>
    </div>
  );
};
export default SubHeader;
