import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const CircularProgressBar = ({ selectedValue }) => {
  const [bar, setBar] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [border, setBorder] = useState([]);

  useEffect(() => {
    if (selectedValue >= 0 && selectedValue <= 100) {
      let value = selectedValue; //65
      let colorBlock = Math.floor(value / 10) * 10; //60
      let halfColorBlock = value % 10 === 1 ? (value % 10) + 1 : value % 10; //4
      let halfGrayBlock = 10 - halfColorBlock; //6

      let grayBox = 100 - (colorBlock + (halfColorBlock + halfGrayBlock));
      let bar = [];
      let backgroundColor = [];
      for (let i = 0; i < Math.floor(value / 10); i++) {
        bar.push(10);
        if (i <= 4) {
          backgroundColor.push("#32b24a");
        } else if (i <= 6) {
          backgroundColor.push("#FFFF00");
        } else if (i <= 8) {
          backgroundColor.push("#ffa500");
        } else {
          backgroundColor.push("#ff0000");
        }
      }
      bar.push(halfColorBlock);
      if (value <= 50) {
        backgroundColor.push("#32b24a");
      } else if (value <= 70) {
        backgroundColor.push("#FFFF00");
      } else if (value <= 90) {
        backgroundColor.push("#ffa500");
      } else {
        backgroundColor.push("#ff0000");
      }

      bar.push(halfGrayBlock);
      backgroundColor.push("#FFFFFF");

      for (let j = 0; j < grayBox / 10; j++) {
        bar.push(10);
        backgroundColor.push("#FFFFFF");
      }
      setBar(bar);
      setBackgroundColor(backgroundColor);
      setBorder(border);
    } else {
      setBar([10, 10, 10, 10, 10, 10, 10, 10, 10, 10]);
      setBorder([[7, 0, 7, 0], 7, 7, 7, 7, 7, 7, 7, 7, 7]);
      if (typeof selectedValue === "number") {
        setBackgroundColor([
          "#32b24a",
          "#32b24a",
          "#32b24a",
          "#32b24a",
          "#32b24a",
          "#FFFF00",
          "#FFFF00",
          "#ffa500",
          "#ffa500",
          "#ff0000",
        ]);
      } else {
        setBackgroundColor([
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
        ]);
      }
    }
    // eslint-disable-next-line
  }, [selectedValue]);

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        data={{
          datasets: [
            {
              data: bar,
              backgroundColor: backgroundColor,
              borderWidth: 7, // Border width between segments
              borderColor: "#F4F4F4", // Border color between segments
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          cutout: 80, // Adjust the cutout percentage as desired
          rotation: 180,
          hover: { mode: null },
          elements: {
            center: {
              text: "Center Text",
              color: "#000", // Color of the text
              fontStyle: "Helvetica", // Font style of the text
              sidePadding: 20, // Padding around the text
            },
          },
        }}
        style={{ height: "110px" }}
      ></Doughnut>
      <h5 className="doughnutText">{selectedValue >= 0 ? selectedValue : undefined}</h5>
    </div>
  );
};

export default CircularProgressBar;
