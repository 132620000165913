import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import fetchData from "../../axios/fetchData";
import {
  coilGateways,
  buildings,
  relationInfo,
  customer,
} from "../../axios/serverUrl";
import {
  activeCoil,
  addCoil,
  activeSnapCoil,
} from "../../redux/actions/coilAction";
import {
  activeBuilding,
  addBuilding,
  addRelation,
} from "../../redux/actions/buildingAction";
import HomePageGrid from "./HomePageGrid";

const HomePage = ({
  coilList,
  buildingList,
  addCoil,
  addBuilding,
  addRelation,
  activeCoil,
  activeSnapCoil,
  activeBuilding,
  relationList,
}) => {
  const [emptyString, setEmptyString] = useState("");
  const [toggleView, setToggleView] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  useEffect(() => {
    if (coilList.length === 0) {
      const getDevices = async () => {
        let device;
        if (
          JSON.parse(localStorage.getItem("userDetail"))?.authority ===
          "CUSTOMER_USER"
        ) {
          device = await fetchData(
            "GET",
            customer +
              "/" +
              JSON.parse(localStorage.getItem("userDetail"))?.customerId.id +
              "/devices?page=" +
              0 +
              "&pageSize=" +
              10,
            {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
            {}
          );
        } else {
          device = await fetchData(
            "GET",
            `${coilGateways}?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
            {
              "X-Authorization": "Bearer " + localStorage.getItem("token"),
            },
            {}
          );
        }
        if (device?.data !== undefined) {
          addCoil(device?.data);
          // activeCoil(device?.data[0].id);
          // activeSnapCoil(device?.data[0].id);
        } else if (device?.errorCode === 11) {
          navigator("/login");
          localStorage.clear();
        }
      };
      getDevices();
    }
    if (typeof relationList != "undefined") {
      if (relationList.length === 0) {
        const getDevices = async () => {
          let device;
          if (
            JSON.parse(localStorage.getItem("userDetail"))?.authority ===
            "CUSTOMER_USER"
          ) {
            device = await fetchData(
              "GET",
              customer +
                "/" +
                JSON.parse(localStorage.getItem("userDetail"))?.customerId.id +
                "/assets?page=" +
                0 +
                "&pageSize=" +
                10,
              { "X-Authorization": "Bearer " + localStorage.getItem("token") },
              {}
            );
          } else {
            device = await fetchData(
              "GET",
              `${buildings}?type=Building&page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
              { "X-Authorization": "Bearer " + localStorage.getItem("token") },
              {}
            );
          }

          if (device?.data) {
            let allRelationData = [];
            let firstCoil = {};
            let firstCoilId = "";
            await Promise.all(
              device?.data?.map(async (item) => {
                const relationData = await fetchData(
                  "GET",
                  relationInfo + "?fromId=" + item?.id.id + "&fromType=ASSET",
                  {
                    "X-Authorization":
                      "Bearer " + localStorage.getItem("token"),
                  },
                  {}
                );
                // eslint-disable-next-line
                relationData?.map((relation) => {
                  let allRelationId = {};
                  allRelationId.BuildingId = relation.from.id;
                  allRelationId.BuildingName = item?.label;
                  allRelationId.CoilId = relation.to.id;
                  allRelationId.CoilName = relation.toName;
                  allRelationData.push(allRelationId);
                });
                if (firstCoilId === "") {
                  firstCoilId = relationData[relationData.length - 1]?.to?.id;
                }
                // activeSnapCoil(relationData[0].to.id);
              })
            );

            firstCoil.entityType = "DEVICE";
            firstCoil.id = firstCoilId;
            activeCoil(firstCoil);
            activeSnapCoil(firstCoil);

            addRelation(allRelationData);
            addBuilding(
              device?.data.filter((item) => item.customerTitle !== null)
            );
            if (device?.data[0]) {
              activeBuilding(device?.data[0].id);
            }
          } else if (device?.errorCode === 11) {
            navigator("/login");
            localStorage.clear();
          }
        };
        getDevices();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm !== "") {
      const filteredItems = buildingList
        .concat(coilList)
        .filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      if (filteredItems.length > 0) {
        setSearchResults(filteredItems);
        setEmptyString("");
      } else {
        setSearchResults([]);
        setEmptyString("No result found!");
      }
    } else {
      setSearchResults([]);
    }
  };
  return (
    <div className="custom-container listing-wrapper">
      <div className="row d-flex justify-content-end home-search-bar">
        <div className="home-search-bar-wrapper d-flex col-4 pr-3 pl-3 pt-0 pb-0  ml-2 mr-4 align-items-center">
          <span className="input-group-append ">
            <button
              className="btn btn-outline-secondary ms-n5 border-0 p-0"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M11.8833 12.875L7.42083 8.4125C7.06667 8.69583 6.65938 8.92014 6.19896 9.08542C5.73854 9.25069 5.24861 9.33333 4.72917 9.33333C3.44236 9.33333 2.3533 8.88767 1.46198 7.99635C0.57066 7.10503 0.125 6.01597 0.125 4.72917C0.125 3.44236 0.57066 2.3533 1.46198 1.46198C2.3533 0.57066 3.44236 0.125 4.72917 0.125C6.01597 0.125 7.10503 0.57066 7.99635 1.46198C8.88767 2.3533 9.33333 3.44236 9.33333 4.72917C9.33333 5.24861 9.25069 5.73854 9.08542 6.19896C8.92014 6.65938 8.69583 7.06667 8.4125 7.42083L12.875 11.8833L11.8833 12.875ZM4.72917 7.91667C5.61458 7.91667 6.36719 7.60677 6.98698 6.98698C7.60677 6.36719 7.91667 5.61458 7.91667 4.72917C7.91667 3.84375 7.60677 3.09115 6.98698 2.47135C6.36719 1.85156 5.61458 1.54167 4.72917 1.54167C3.84375 1.54167 3.09115 1.85156 2.47135 2.47135C1.85156 3.09115 1.54167 3.84375 1.54167 4.72917C1.54167 5.61458 1.85156 6.36719 2.47135 6.98698C3.09115 7.60677 3.84375 7.91667 4.72917 7.91667Z"
                  fill="#707C82"
                />
              </svg>
            </button>
          </span>
          <input
            className="form-control border-0 p-0 m-0 h-20"
            type="search"
            value={searchItem}
            onChange={handleInputChange}
            placeholder="Search Buildings, AHUs, or AHU by Building"
          />
          <ul>{/* {filteredItems.map(user => <li>{user.name}</li>)} */}</ul>
        </div>
        <div
          className={
            "grid-view w-auto p-2 ml-2 mr-2" + (toggleView ? " active" : "")
          }
          onClick={() => setToggleView(true)}
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M0.184082 7.42489V0.235352H7.26011V7.42489H0.184082ZM0.184082 16.4118V9.22228H7.26011V16.4118H0.184082ZM9.02911 7.42489V0.235352H16.1051V7.42489H9.02911ZM9.02911 16.4118V9.22228H16.1051V16.4118H9.02911ZM1.95309 5.62751H5.4911V2.03274H1.95309V5.62751ZM10.7981 5.62751H14.3361V2.03274H10.7981V5.62751ZM10.7981 14.6144H14.3361V11.0197H10.7981V14.6144ZM1.95309 14.6144H5.4911V11.0197H1.95309V14.6144Z"
              fill="#707C82"
            />
          </svg>
        </div>
        <div
          className={
            "list-view w-auto p-2 ml-2 mr-2" + (!toggleView ? " active" : "")
          }
          onClick={() => setToggleView(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
          >
            <path
              d="M5.75 3.53803V0.896729H23.25V3.53803H5.75ZM5.75 8.82064V6.17934H23.25V8.82064H5.75ZM5.75 14.1033V11.4619H23.25V14.1033H5.75ZM2 3.53803C1.64583 3.53803 1.34896 3.41147 1.10938 3.15835C0.869792 2.90522 0.75 2.59157 0.75 2.21738C0.75 1.8432 0.869792 1.52954 1.10938 1.27642C1.34896 1.02329 1.64583 0.896729 2 0.896729C2.35417 0.896729 2.65104 1.02329 2.89062 1.27642C3.13021 1.52954 3.25 1.8432 3.25 2.21738C3.25 2.59157 3.13021 2.90522 2.89062 3.15835C2.65104 3.41147 2.35417 3.53803 2 3.53803ZM2 8.82064C1.64583 8.82064 1.34896 8.69408 1.10938 8.44095C0.869792 8.18783 0.75 7.87417 0.75 7.49999C0.75 7.1258 0.869792 6.81215 1.10938 6.55902C1.34896 6.3059 1.64583 6.17934 2 6.17934C2.35417 6.17934 2.65104 6.3059 2.89062 6.55902C3.13021 6.81215 3.25 7.1258 3.25 7.49999C3.25 7.87417 3.13021 8.18783 2.89062 8.44095C2.65104 8.69408 2.35417 8.82064 2 8.82064ZM2 14.1033C1.64583 14.1033 1.34896 13.9767 1.10938 13.7236C0.869792 13.4704 0.75 13.1568 0.75 12.7826C0.75 12.4084 0.869792 12.0948 1.10938 11.8416C1.34896 11.5885 1.64583 11.4619 2 11.4619C2.35417 11.4619 2.65104 11.5885 2.89062 11.8416C3.13021 12.0948 3.25 12.4084 3.25 12.7826C3.25 13.1568 3.13021 13.4704 2.89062 13.7236C2.65104 13.9767 2.35417 14.1033 2 14.1033Z"
              fill="#707C82"
            />
          </svg>
        </div>
      </div>
      {searchResults.length !== 0 ? (
        <HomePageGrid
          title=""
          products={searchResults}
          toggleView={toggleView}
          emptyString={emptyString}
          searchItem={searchItem}
        />
      ) : emptyString !== "" ? (
        <div class="empty-search-string">{emptyString}</div>
      ) : (
        <>
          <HomePageGrid
            title="Buildings"
            products={buildingList}
            toggleView={toggleView}
            emptyString={emptyString}
            searchItem={searchItem}
          />

          <HomePageGrid
            title="SmartCoil Gateways"
            products={coilList}
            toggleView={toggleView}
            emptyString={emptyString}
            searchItem={searchItem}
          />
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
  buildingList: state.buildingList.buildingList,
  relationList: state.buildingList.relationList,
});
export default connect(mapStateToProps, {
  addCoil,
  activeCoil,
  activeSnapCoil,
  addBuilding,
  addRelation,
  activeBuilding,
})(HomePage);
