import {
  ADD_COIL,
  REMOVE_COIL,
  UPDATE_COIL,
  ACTIVE_COIL,
  ACTIVE_SNAP_COIL,
  COILLIST,
  ACTIVE_LIST_COIL,
  RESET_ACTION,
  REMOVE_RECENT_COIL,
  REMOVE_RECENT_SNAP_COIL,
  INACTIVE_COIL,
} from "../constant";

export const CoilList = (payload) => ({ type: COILLIST, payload: { payload } });

export const addCoil = (payload) => ({
  type: ADD_COIL,
  payload: payload,
});

export const updateCoil = (payload) => ({
  type: UPDATE_COIL,
  payload: payload,
});

export const activeCoil = (payload) => ({
  type: ACTIVE_COIL,
  payload: payload,
});
export const inActiveCoil = (payload) => ({
  type: INACTIVE_COIL,
  payload: payload,
});
export const activeSnapCoil = (payload) => ({
  type: ACTIVE_SNAP_COIL,
  payload: payload,
});

export const removeCoil = (payload) => ({
  type: REMOVE_COIL,
  payload: payload,
});

export const getActiveList = (payload) => ({
  type: ACTIVE_LIST_COIL,
  payload: payload,
});

export const setLogout = (payload) => ({
  type: RESET_ACTION,
  payload: payload,
});
export const removeSnapRecentCoil = (payload) => ({
  type: REMOVE_RECENT_SNAP_COIL,
  payload: payload,
});
export const removeRecentCoil = (payload) => ({
  type: REMOVE_RECENT_COIL,
  payload: payload,
});
