export const ADD_COIL = "ADD_COIL";
export const REMOVE_COIL = "REMOVE_COIL";
export const UPDATE_COIL = "UPDATE_COIL";
export const ACTIVE_COIL = "ACTIVE_COIL";
export const ACTIVE_SNAP_COIL = "ACTIVE_SNAP_COIL";
export const ACTIVE_LIST_COIL = "ACTIVE_LIST_COIL";
export const COILLIST = "COILLIST";
export const REMOVE_RECENT_COIL = "REMOVE_RECENT_COIL";
export const REMOVE_RECENT_SNAP_COIL = "REMOVE_RECENT_SNAP_COIL";
export const INACTIVE_COIL = "INACTIVE_COIL";

export const ADD_BUILDING = "ADD_BUILDING";
export const ADD_RELATION = "ADD_RELATION";
export const REMOVE_BUILDING = "REMOVE_BUILDING";
export const UPDATE_BUILDING = "UPDATE_BUILDING";
export const ACTIVE_BUILDING = "ACTIVE_BUILDING";
export const ACTIVE_LIST_BUILDING = "ACTIVE_LIST_BUILDING";
export const BUILDINGLIST = "COILLIST";

export const RESET_ACTION = "RESET_ACTION";
