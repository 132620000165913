import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./assets/css/custom.css";
import "./assets/css/plugins.css";
import "./App.css";
import CustomRouter, {AdminRouter, AuthRouter, HomeRouter} from "./pages";
import Activate from "./pages/Auth/Activate";
import CheckEmail from "./pages/Auth/CheckEmail";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import FounlingMetrics from "./pages/Customer/FounlingMetrics";
import Performance from "./pages/Customer/Performance";
import Predictive from "./pages/Customer/Predictive";
import RawData from "./pages/Customer/RawData";
import Snapshot from "./pages/Customer/Snapshot";
import Start from "./pages/Customer/Start";
import AdminConsole from "./pages/Admin/AdminConsole";
// import ViewAll from "./pages/ViewAll";
import BuildingListing from "./pages/Home/PageListing/BuildingListing";
import CoilListing from "./pages/Home/PageListing/CoilListing";
import RecentListing from "./pages/Home/PageListing/RecentListing";
import HomePage from "./pages/HomePage";
import ResetPassword from "./pages/Auth/ResetPassword";
import ErrorBoundary from "./pages/ErrorBoundary";
import {initAnalytics} from "./analytics/analytics";

initAnalytics();

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<AuthRouter component={<Login />} />} />

          <Route
            path="/register"
            element={<AuthRouter component={<Register />} />}
          />
          <Route
            path="/reset-password"
            element={<AuthRouter component={<ResetPassword />} />}
          />
          <Route
            path="/activate"
            element={<AuthRouter component={<Activate />} />}
          />
          <Route
            path="/forgot-password"
            element={<AuthRouter component={<ForgotPassword />} />}
          />
          <Route
            path="/check-email"
            element={<AuthRouter component={<CheckEmail />} />}
          />
          <Route
            path="/home"
            element={<HomeRouter component={<HomePage />} />}
          />
          <Route
            path="home/coils"
            element={<HomeRouter component={<CoilListing />} />}
          />
          <Route
            path="/home/buildings"
            element={<HomeRouter component={<BuildingListing />} />}
          />
          <Route
            path="home/recent"
            element={<HomeRouter component={<RecentListing />} />}
          />
          <Route
            path="/admin"
            element={<AdminRouter component={<AdminConsole />} />}
          />
          <Route path="/" element={<CustomRouter component={<Start />} />} />
          <Route
            path="/customer/snapshot"
            element={<CustomRouter component={<Snapshot />} />}
          />
          <Route
            path="/customer/fouling"
            element={<CustomRouter component={<FounlingMetrics />} />}
          />
          <Route
            path="/customer/performance"
            element={<CustomRouter component={<Performance />} />}
          />
          <Route
            path="/customer/raw-data"
            element={<CustomRouter component={<RawData />} />}
          />
          <Route
            path="/customer/predictive"
            element={<CustomRouter component={<Predictive />} />}
          />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
