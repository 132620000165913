import BasicInformation from "./BasicInformation";
import AddressInformation from "./AddressInformation";
import PasswordInformation from "./PasswordInformation";
import { useEffect, useState } from "react";

const Profile = () => {
  const [userType, setUserType] = useState("");
  const userDetail = JSON.parse(localStorage.userDetail);
  useEffect(() => {
    if (localStorage.tenantDetail) {
      setUserType("tenant");
    } else if (localStorage.customerDetail) {
      setUserType("customer");
    }
    // eslint-disable-next-line
  }, [localStorage.tenantDetail || localStorage.customerDetail]);
  return (
    <div>
      <div className="col-md-12 row">
        {/* Profile Photo */}
        <form>
          <div className="d-flex align-items-center profile-view">
            <span className="d-flex align-items-center">
              <div className="circle-highlight-lg">
                {userDetail?.firstName && userDetail?.firstName?.[0]}
                {userDetail?.lastName && userDetail?.lastName?.[0]}
              </div>
              <h5 className="admin-tabs active mb-0 ms-3 ">
                {(userDetail?.firstName || "") +
                  " " +
                  (userDetail?.lastName || "")}
              </h5>
            </span>
            <div className="ms-4 d-none">
              <div className="d-flex align-items-center">
                <h6 className="basis-title">Upload / Edit</h6>
                <div className="basis-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 14.375V17.5H5.625L14.842 8.28302L11.717 5.15802L2.5 14.375ZM17.258 5.86602C17.3353 5.78892 17.3965 5.69735 17.4384 5.59654C17.4802 5.49573 17.5017 5.38766 17.5017 5.27852C17.5017 5.16938 17.4802 5.06131 17.4384 4.9605C17.3965 4.85969 17.3353 4.76811 17.258 4.69102L15.308 2.74102C15.2309 2.66376 15.1393 2.60248 15.0385 2.56066C14.9377 2.51884 14.8296 2.49731 14.7205 2.49731C14.6114 2.49731 14.5033 2.51884 14.4025 2.56066C14.3017 2.60248 14.2101 2.66376 14.133 2.74102L12.608 4.26602L15.733 7.39102L17.258 5.86602Z"
                      fill="#BCBCBC"
                    />
                  </svg>
                </div>
              </div>
              <p className="basis-desc">
                Photos should be at least 180x180 and in JPG format.
              </p>
            </div>
          </div>
        </form>
        {/* Basic Info  */}
        <div className="col-md-5">
          <h6 className="admin-subtitle">Basic Information</h6>
          <label className="admin-subtitle-text mt-3">My Information</label>
          <div className="mt-2">
            <BasicInformation userDetail={userDetail} userType={userType} />
            {localStorage.tenantDetail ? (
              <AddressInformation
                userAddressDetail={JSON.parse(localStorage.tenantDetail)}
                userType="tenant"
              />
            ) : localStorage.customerDetail ? (
              <AddressInformation
                userAddressDetail={JSON.parse(localStorage.customerDetail)}
                userType="customer"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-md-2"></div>
        {/* change Password  */}
        <div className="col-md-5 change-password-view">
          <h6 className="admin-subtitle">Change Password</h6>
          <label className="admin-subtitle-text mt-3">
            Verify Current Password
          </label>
          <PasswordInformation userDetail={userDetail} userType={userType} />
        </div>
      </div>
    </div>
  );
};
export default Profile;
