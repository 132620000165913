import coilReducer from "./coilReducer";
import buildingReducer from "./buildingReducer";
import { combineReducers } from "redux";

//Combine all the sub reducers
const rootReducer = combineReducers({
  coilList: coilReducer,
  buildingList: buildingReducer,
});

export default rootReducer;
