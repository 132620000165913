import FooterLogo from "../../assets/images/footer-logo.png";
const AuthFooter = ({ isAdmin = false }) => {
  return (
    <div className="d-flex justify-content-end align-items-end align-self-end me-5 pe-4">
      <img src={FooterLogo} alt="FooterLogo" />
      <h6
        className="ml-3 footer-text mb-0"
        style={{ color: isAdmin ? "#377ABF" : "white" }}
      >
        © 2024 All rights reserved.
      </h6>
    </div>
  );
};
export default AuthFooter;
