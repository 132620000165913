import React from "react";

const Activate = () => {
  return (
    <div className="col-md-12 row d-flex justify-content-center align-items-center text-center">
      <div className="col-md-5 bg-white box-shadow-login m-0 mb-5">
        <h2 className="active-text">Success!</h2>
        <div className="mb-5 ">
          <h6 className="active-desc">beth@mlbcreative.com</h6>
          <button type="submit" className="btn btn-primary-new mt-4">
            Activate Account
          </button>
        </div>
      </div>
    </div>
  );
};
export default Activate;
