import moment from "moment/moment";
const lineChartDataOption = {
  grid: {
    show: true,
    left: "0%",
    right: "0%",
    bottom: "0%",
    height: "370px",
    containLabel: true,
  },
  toolbox: {
    right: 135,
    itemSize: 15,
    itemGap: 8,
    top: -6,
    feature: {
      dataZoom: {
        iconStyle: { borderWidth: 2, borderColor: "#414042" },
        xAxisIndex: [0, 2],
        yAxisIndex: [0, 2],
        title: {
          back: "",
        },
        icon: {
          back: "blank",
        },
      },
      restore: {
        iconStyle: { borderWidth: 2, borderColor: "#414042" },
      },
    },
  },
  tooltip: {
    triggerOn: "mousemove",
    trigger: "axis",
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    splitLine: {
      show: true,
      lineStyle: {
        color: "#0BBBEA",
      },
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      // Be careful: it's axisTick and not axisTicks
      show: false,
    },
    data: [
      moment(Date.now() - 86400000 * 7).format("MMM DD"),
      moment(Date.now() - 86400000 * 6).format("MMM DD"),
      moment(Date.now() - 86400000 * 5).format("MMM DD"),
      moment(Date.now() - 86400000 * 4).format("MMM DD"),
      moment(Date.now() - 86400000 * 3).format("MMM DD"),
      moment(Date.now() - 86400000 * 2).format("MMM DD"),
      moment(Date.now() - 86400000).format("MMM DD"),
      moment(Date.now()).format("MMM DD"),
    ],
  },
  textStyle: {
    color: "#025173",
    fontSize: 30,
    fontStyle: "normal",
    fontWeight: "500",
    fontFamily: "Barlow",
    lineHeight: "160%",
  },
  yAxis: {
    type: "value",
    splitLine: {
      show: true,
      lineStyle: {
        color: "#0BBBEA",
      },
    },
    splitNumber: 3,
    minInterval: 1,
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    axisLabel: {
      show: true,
      formatter: (val) => val.toFixed(0),
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  replaceMerge: ["xAxis", "yAxis", "series"],
  lazyUpdate: true,
  notMerge: true,
  series: [],
};
export default lineChartDataOption;
