import {getFullLabel, getLabel} from "../../../utils/device";

const RecentChoiceForSnapshot = ({ item, handleRecentChoice, index }) => {
  return (
    <button
      className="border-none bg-transparent tag"
      onClick={() => (item?.active ? "" : handleRecentChoice(item))}
    >
      <input
        type="checkbox"
        name="recent-choice"
        className="tag__input"
        checked={item?.active}
        onChange={() => (item?.active ? "" : handleRecentChoice(item))}
      />
      <label className="tag__label_snapshot" title={getFullLabel(item)}>{getLabel(item)}</label>
    </button>
  );
};

export default RecentChoiceForSnapshot;
