import fetchData from "./fetchData";
import {telemetry} from "./serverUrl";

export const getSharedAttributes = (device) => {
    return fetchData(
        "GET",
        telemetry +
        "/" +
        device.id.entityType +
        "/" +
        device.id.id +
        "/values/attributes?keys=flvl,smartcoil_state",
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then(response => {
        if (response?.length === 0) {
            return {};
        }
        return response.reduce((result, attribute) => {
            result[attribute.key] = attribute.value;
            return result;
        }, {});
    })
}
