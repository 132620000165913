import { Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ContextAwareToggle from "./ContextAwareToggle";
import RecentChoice from "../RecentChoice";
import RecentChoiceForSnapshot from "../RecentChoiceForSnapshot";
import { useEffect, useState } from "react";

const AhuSmartCoil = ({
  Buildings,
  AhuSmartCoilData,
  handleRecentAhu,
  relationList,
}) => {
  const location = useLocation();
  const isSnapshotRoute = location.pathname.includes("snapshot");
  const [coilsData, setCoilsData] = useState(AhuSmartCoilData);

  useEffect(() => {
    const filteredAhuSmartCoilData = AhuSmartCoilData.filter((coil) => {
      return relationList?.map((item) => item.CoilId).includes(coil.id.id);
    })?.map((filteredCoil) => ({
      ...filteredCoil,
      parentBuildingId: relationList.find(
        (item) => item.CoilId === filteredCoil.id.id
      ).BuildingId,
    }));
    setCoilsData(filteredAhuSmartCoilData);
  }, [relationList, AhuSmartCoilData]);

  return (
    <>
      {Buildings?.map(
        (building, index) =>
          coilsData.filter((item) => item.parentBuildingId === building.id.id)
            .length > 0 && (
            <Accordion
              defaultActiveKey={Buildings?.map((_, index) => index)}
              key={index}
            >
              <Card className="p-0 h-auto mb-0 border-none" key={index}>
                <Card.Header
                  className="bg-transparent pe-0 pb-2"
                  style={{ paddingLeft: "10px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span> {building.name}</span>
                    <ContextAwareToggle eventKey={index}></ContextAwareToggle>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body className="p-0">
                    <div
                      className="tag-list"
                      style={{
                        maxHeight: "150px",
                        height: "auto",
                        overflowY: "auto",
                      }}
                    >
                      {isSnapshotRoute ? (
                        <>
                          {coilsData &&
                            coilsData
                              ?.sort(
                                (a, b) => {
                                  const aValue = a?.label ?? a?.name?.split("-")?.[1];
                                  const bValue = b?.label ?? b?.name?.split("-")?.[1];

                                  return String(aValue).localeCompare(String(bValue));
                                }
                              )
                              ?.filter(
                                (item) =>
                                  item.parentBuildingId === building.id.id
                              )
                              ?.map((item, index) => (
                                <RecentChoiceForSnapshot
                                  index={index}
                                  key={index}
                                  item={item}
                                  handleRecentChoice={handleRecentAhu}
                                />
                              ))}
                        </>
                      ) : (
                        <>
                          {coilsData &&
                            coilsData
                              ?.filter(
                                (item) =>
                                  item.parentBuildingId === building.id.id
                              )
                              ?.sort(
                                (a, b) => {
                                  const aValue = a?.label ?? a?.name?.split("-")?.[1];
                                  const bValue = b?.label ?? b?.name?.split("-")?.[1];

                                  return String(aValue).localeCompare(String(bValue));
                                }
                              )
                              ?.map((item, index) => (
                                <RecentChoice
                                  customClass="tag__label"
                                  key={index}
                                  item={item}
                                  handleRecentChoice={handleRecentAhu}
                                />
                              ))}
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  relationList: state.buildingList.relationList,
});
export default connect(mapStateToProps, {})(AhuSmartCoil);
