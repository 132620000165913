import React from "react";
import { Modal } from "react-bootstrap";
import ZoomOut from "../../assets/images/zoomOut.png";

const FullScreenModal = ({
  title,
  item,
  onHide,
  onItemChange,
  children,
  ...rest
}) => {
  return (
    <Modal {...rest} onHide={onHide} fullscreen style={{ background: "white" }}>
      <Modal.Body>
        <div className="p-2">
          <div className="d-flex justify-content-between">
            <h6 className="title">{title}</h6>
            <span
              className="mr-3 cursor-pointer"
              style={{ cursor: "pointer" }}
              onClick={() => onHide()}
            >
              <img
                src={ZoomOut}
                alt="zoom"
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
          <div className="p-2">{children}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FullScreenModal;
