import { useState } from "react";
import CustomLoader from "../../../../component/Loader/Loader";
import fetchData from "../../../../axios/fetchData";
import { login, register } from "../../../../axios/serverUrl";
const BasicInformation = ({ userDetail, userType }) => {
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(
    userDetail?.firstName ? userDetail.firstName : null
  );
  const [lastName, setLastName] = useState(
    userDetail?.lastName ? userDetail.lastName : null
  );
  const [name, setUserName] = useState(
    userDetail?.name ? userDetail.name : null
  );
  const [email, setEmail] = useState(
    userDetail?.email ? userDetail.email : null
  );
  const [phone, setPhone] = useState(
    userDetail?.phone ? userDetail.phone : null
  );

  const [userData, setUserData] = useState({
    name,
    firstName,
    lastName,
    email,
    phone,
  });
  const onSubmitInformation = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data;
    if (userType === "tenant") {
      data = await fetchData(
        "POST",
        login,
        {},
        {
          username: process.env.REACT_APP_SYS_ADMIN_USERNAME,
          password: process.env.REACT_APP_SYS_ADMIN_PASSWORD,
        }
      );
      if (data?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
    } else {
      data = await fetchData(
        "POST",
        login,
        {},
        {
          username: process.env.REACT_APP_TENANT_USERNAME,
          password: process.env.REACT_APP_TENANT_PASSWORD,
        }
      );
      if (data?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
    }

    if (data?.token) {
      let systemAdminToken = data.token;
      userData.id = {
        entityType: "USER",
        id: userDetail.id.id,
      };
      userData.customerId = {
        entityType: "CUSTOMER",
        id: userDetail.customerId.id,
      };
      userData.tenantId = {
        entityType: "TENANT",
        id: userDetail.tenantId.id,
      };
      if (userType === "tenant") {
        userData.authority = "TENANT_ADMIN";
      } else {
        userData.authority = "CUSTOMER_USER";
      }
      if (userData?.email === null) {
        userData.email = userDetail.sub;
      }
      const updatedTenant = await fetchData(
        "POST",
        register,
        { "X-Authorization": "Bearer " + systemAdminToken },
        userData
      );
      if (updatedTenant?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
      if (updatedTenant?.email) {
        localStorage.setItem("userDetail", JSON.stringify(updatedTenant));
        setLoading(false);
      } else if (updatedTenant?.status === 400) {
        setLoading(false);
        alert(updatedTenant?.message);
      }
    } else if (data?.errorCode === 11) {
      setLoading(false);
      navigator("/login");
      localStorage.clear();
    } else {
      if (userType === "tenant") {
        alert("You have to login as system admin");
      } else {
        alert("You have to login as tenant admin");
      }
      setLoading(false);
    }
  };
  return (
    <form onSubmit={onSubmitInformation}>
      <input
        className="form-control-normal"
        placeholder="Username"
        value={name}
        onChange={(e) => {
          setUserData({ ...userData, name: e.target.value });
          setUserName(e.target.value);
        }}
      />
      <input
        className="form-control-normal my-3"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => {
          setUserData({ ...userData, firstName: e.target.value });
          setFirstName(e.target.value);
        }}
      />
      <input
        className="form-control-normal"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => {
          setUserData({ ...userData, lastName: e.target.value });
          setLastName(e.target.value);
        }}
      />
      <input
        className="form-control-normal my-3"
        placeholder="Email"
        value={email}
        required
        onChange={(e) => {
          setUserData({ ...userData, email: e.target.value });
          setEmail(e.target.value);
        }}
      />
      <input
        className="form-control-normal"
        placeholder="Phone (optional)"
        value={phone}
        onChange={(e) => {
          setUserData({ ...userData, phone: e.target.value });
          setPhone(e.target.value);
        }}
      />
      <div className="d-flex justify-content-end my-3">
        <button className="btn-update" type="submit">
          Update
        </button>
      </div>
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </form>
  );
};

export default BasicInformation;
