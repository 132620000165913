const getRandomColor = (index) => {
  const color = [
    "#00E680",
    "#6680B3",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#66991A",
    "#FF99E6",
    "#FF1A66",
    "#E6331A",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];
  return color[randomIntFromInterval()];
  // return "#" + Math.floor(Math.random() * 16777215).toString(16);
};
export default getRandomColor;

export const randomIntFromInterval = () => {
  // min and max included
  return Math.floor(Math.random() * (29 - 0 + 1) + 0);
};

export const TELEMETRY_OPTION_ENUMS = {
  all: "Show All",
  // raw data smart coil gen1
  cc_lat: "Temperature - Supply",
  cc_larh: "Relative Humidity - Supply",
  cc_eat: "Temperature - Return",
  cc_earh: "Relative Humidity - Return",
  sf_amp: "Current",
  air_vel: "Air Velocity",
  chw_st: "Chilled Water Supply",
  chw_rt: "Chilled Water Return",
  oa_temp: "Outside Air",
  oa_humidity: "Outside Humidity",
  oa_pressure: "Outside Air Pressure",  

  // snapshot data
  ahu_flvl: "Coiling Fouling Level",
  clean_serv: "Months till Next Service",
  ahu_event: "Event - Cleaning",
  totsav_ener: "Total Energy Savings",
  totcostsav_ener: "Total Cost Savings",
  co2_sav: "Carbon Offset",

  // performance smart coil gen1 data
  btu: "BTU/Hr",
  sf_kwh: "Supply Fan Energy Consumption",
  cc_kwh: "CHW Coil Energy Consumption",
  cc_pump_kwh: "CHW Pump Energy Consumption",
  chw_dt: "CHW Delta T",
  flow_energy: "Water BTU",
  hc_eat: "HWC Entering Air Temperature",
  hc_lat: "HWC Leaving Air Temperature",
  hw_st: "HWC Supply Water Temperature",
  hw_rt: "HWC Return Water Temperature",
  gc_eat: "GWC Entering Air Temperature",
  gc_lat: "GWC Leaving Air Temperature",
  gw_st: "GWC Supply Water Temperature",
  gw_rt: "GWC Return Water Temperature",
  

  // performance bio mik
  flow_temp_diff: "CHW Delta T",

  // raw data bio mik
  temperature_supply: "Temperature - Supply",
  humidity_supply: "Relative Humidity - Supply",
  temperature_return: "Temperature - Return",
  humidity_return: "Relative Humidity - Return",
  current: "Current",
  air_velocity: "Air Velocity",
  flow_water_in_temp: "Chilled Water Supply",
  flow_water_out_temp: "Chilled Water Return",
};

export const symbolList = {
  temperature_supply: "° F",
  cc_lat: "° F",
  cc_larh: " %",
  humidity_supply: " %",
  oa_humidity: " %",
  temperature_return: "° F",
  cc_eat: "° F",
  humidity_return: " %",
  cc_earh: " %",
  current: " A",
  sf_amp: " A",
  air_velocity: " FT/MIN",
  oa_temp: "° F",
  air_vel: " FT/MIN",
  oa_pressure: " hPA",
  flow_water_in_temp: "° F",
  chw_st: "° F",
  flow_water_out_temp: "° F",
  chw_rt: "° F",
  sf_kwh: " kWh",
  chw_dt: "° F",
  flow_temp_diff: "° F",
};
