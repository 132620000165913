import {
  addDays,
  addMonths,
  addWeeks,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

const getDefaultRanges = (date) => [
  {
    label: "Today",
    startDate: startOfDay(new Date()),
    endDate: new Date().getTime(),
    chartInterval: 0,
  },
  {
    label: "Yesterday",
    startDate: startOfDay(addDays(new Date().getTime(), -1)),
    endDate: new Date().getTime(),
    chartInterval: 0,
  },
  {
    label: "This Week",
    startDate: startOfWeek(new Date()),
    endDate: new Date().getTime(),
    chartInterval: 86400000 / 4,
  },
  {
    label: "Last 7 Days",
    startDate: addWeeks(new Date(), -1),
    endDate: new Date().getTime(),
    chartInterval: 86400000 / 3,
  },
  {
    label: "Last 30 Days",
    startDate: startOfDay(addDays(new Date(), -30)),
    endDate: new Date().getTime(),
    chartInterval: 86400000, // 1 day in milliseconds
  },
  {
    label: "Last 3 months",
    startDate: startOfMonth(addMonths(new Date(), -2)),
    endDate: new Date().getTime(),
    chartInterval: 2629800000,
  },
  {
    label: "Last 6 months",
    startDate: startOfMonth(addMonths(new Date(), -5)),
    endDate: new Date().getTime(),
    chartInterval: 2629800000,
  },
  {
    label: "Last Year",
    startDate: startOfYear(addMonths(new Date(), -12)),
    endDate: new Date().getTime(),
    chartInterval: 86400000 * 30, // 30 days in milliseconds (approximate)
  },
];

export const defaultRanges = getDefaultRanges(new Date());
