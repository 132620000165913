import RenderChart from "../../../component/RenderData/RenderChart";
import lineChartDataOption from "./LineChartData";
import {usePageTracking} from "../../../analytics/analytics";

function FounlingMetrics() {
  usePageTracking();
  return (
    <RenderChart
      chartOptions={lineChartDataOption}
      chartTitle="Fouling Metrics"
      chartSubTitle="COIL FOULING LEVEL"
      dropdownOptions={["ahu_flvl"]}
      dropdownOptionsBioKey={["ahu_flvl"]}
    />
  );
}
export default FounlingMetrics;
