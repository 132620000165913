import React, { useState } from "react";
import { connect } from "react-redux";
import PageListing from "../PageListing";

const BuildingListing = ({ buildingList }) => {
  const [toggleView, setToggleView] = useState(true);

  return (
    <div className="custom-container listing-wrapper">
      <h2 className="listing-title">Building</h2>
      <span className="listing-sub-title">20 AHUs</span>

      <div className="divide" />

      <div className="listing-header d-flex justify-content-end mt-3 mb-4">
        <div className="select-wrapper d-block d-md-flex mb-3 mb-md-0">
          <div className="d-flex mb-3 mb-md-0">
            <select className="form-select" aria-label="select example">
              <option value="" selected>
                Bulk Actions
              </option>
              <option value="1">Actions One</option>
              <option value="2">Actions Two</option>
              <option value="3">Actions Three</option>
            </select>
          </div>

          <div className="d-flex">
            <select
              className="form-select bg-transparent"
              aria-label="select example"
            >
              <option value="">Change role to</option>
              <option value="1">role One</option>
              <option value="2">role Two</option>
              <option value="3">role Three</option>
            </select>
          </div>
        </div>
        <div
          className="grid-view  w-auto p-2 ml-2 mr-2"
          onClick={() => setToggleView(true)}
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M0.184082 7.42489V0.235352H7.26011V7.42489H0.184082ZM0.184082 16.4118V9.22228H7.26011V16.4118H0.184082ZM9.02911 7.42489V0.235352H16.1051V7.42489H9.02911ZM9.02911 16.4118V9.22228H16.1051V16.4118H9.02911ZM1.95309 5.62751H5.4911V2.03274H1.95309V5.62751ZM10.7981 5.62751H14.3361V2.03274H10.7981V5.62751ZM10.7981 14.6144H14.3361V11.0197H10.7981V14.6144ZM1.95309 14.6144H5.4911V11.0197H1.95309V14.6144Z"
              fill="#707C82"
            />
          </svg>
        </div>
        <div
          className="list-view w-auto p-2 ml-2 mr-2"
          onClick={() => setToggleView(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="15"
            viewBox="0 0 24 15"
            fill="none"
          >
            <path
              d="M5.75 3.53803V0.896729H23.25V3.53803H5.75ZM5.75 8.82064V6.17934H23.25V8.82064H5.75ZM5.75 14.1033V11.4619H23.25V14.1033H5.75ZM2 3.53803C1.64583 3.53803 1.34896 3.41147 1.10938 3.15835C0.869792 2.90522 0.75 2.59157 0.75 2.21738C0.75 1.8432 0.869792 1.52954 1.10938 1.27642C1.34896 1.02329 1.64583 0.896729 2 0.896729C2.35417 0.896729 2.65104 1.02329 2.89062 1.27642C3.13021 1.52954 3.25 1.8432 3.25 2.21738C3.25 2.59157 3.13021 2.90522 2.89062 3.15835C2.65104 3.41147 2.35417 3.53803 2 3.53803ZM2 8.82064C1.64583 8.82064 1.34896 8.69408 1.10938 8.44095C0.869792 8.18783 0.75 7.87417 0.75 7.49999C0.75 7.1258 0.869792 6.81215 1.10938 6.55902C1.34896 6.3059 1.64583 6.17934 2 6.17934C2.35417 6.17934 2.65104 6.3059 2.89062 6.55902C3.13021 6.81215 3.25 7.1258 3.25 7.49999C3.25 7.87417 3.13021 8.18783 2.89062 8.44095C2.65104 8.69408 2.35417 8.82064 2 8.82064ZM2 14.1033C1.64583 14.1033 1.34896 13.9767 1.10938 13.7236C0.869792 13.4704 0.75 13.1568 0.75 12.7826C0.75 12.4084 0.869792 12.0948 1.10938 11.8416C1.34896 11.5885 1.64583 11.4619 2 11.4619C2.35417 11.4619 2.65104 11.5885 2.89062 11.8416C3.13021 12.0948 3.25 12.4084 3.25 12.7826C3.25 13.1568 3.13021 13.4704 2.89062 13.7236C2.65104 13.9767 2.35417 14.1033 2 14.1033Z"
              fill="#707C82"
            />
          </svg>
        </div>
      </div>

      <PageListing listData={buildingList} toggleView={toggleView} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  buildingList: state.buildingList.buildingList,
});
export default connect(mapStateToProps, {})(BuildingListing);
