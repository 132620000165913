import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const AuthHeader = () => {
  return (
    <div className="d-flex justify-content-start align-items-center bg-white">
      <div className="layout-topbar authheader-layout-topbar">
        <Link className="layout-topbar-logo " to="/">
          <img src={Logo} alt="logo"  />
        </Link>
        <h6 className="d-none d-md-block header-title mb-0 pr-5">
          The first real-time monitoring system for AHU Coils
        </h6>
      </div>
    </div>
  );
};
export default AuthHeader;
