import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AhuSmartCoil from "./AhuSmartCoil";
// import { SmartColiData } from "./AhuSmartCoil/AhuSmartCoilData";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchData from "../../axios/fetchData";
import { customer, relationInfo } from "../../axios/serverUrl";
import {
  activeBuilding,
  addBuilding,
  addRelation,
} from "../../redux/actions/buildingAction";
import {
  activeCoil,
  activeSnapCoil,
  addCoil,
  removeRecentCoil,
  removeSnapRecentCoil,
} from "../../redux/actions/coilAction";
import MostRecent from "./MostRecent";

const Sidebar = ({
  recentList,
  recentSnapList,
  coilList,
  activeSnapList,
  buildingList,
  addCoil,
  addBuilding,
  addRelation,
  activeCoil,
  activeSnapCoil,
  activeList,
  activeBuilding,
  isMobileView,
  handleSidebar,
  relationList,
  removeRecentCoil,
  removeSnapRecentCoil,
}) => {
  const navigator = useNavigate();
  const location = useLocation();
  const isSnapshotRoute = location.pathname.includes("snapshot");
  useEffect(() => {
    if (coilList.length === 0) {
      const getDevices = async () => {
        const device = await fetchData(
          "GET",
          customer +
            "/" +
            JSON.parse(localStorage.getItem("userDetail"))?.customerId.id +
            "/devices?page=" +
            0 +
            "&pageSize=" +
            10,
          {
            "X-Authorization": "Bearer " + localStorage.getItem("token"),
          },
          {}
        );
        if (device?.data !== undefined) {
          addCoil(device?.data);
          // activeCoil(device?.data[0].id);
          // activeSnapCoil(device?.data[0].id);
        } else if (device?.errorCode === 11) {
          navigator("/login");
          localStorage.clear();
        }
      };
      getDevices();
    }

    if (typeof relationList != "undefined") {
      if (relationList.length === 0) {
        const getDevices = async () => {
          const device = await fetchData(
            "GET",
            customer +
              "/" +
              JSON.parse(localStorage.getItem("userDetail"))?.customerId.id +
              "/assets?page=" +
              0 +
              "&pageSize=" +
              10,
            { "X-Authorization": "Bearer " + localStorage.getItem("token") },
            {}
          );
          if (device?.data) {
            let allRelationData = [];
            let firstCoil = {};
            let firstCoilId = "";
            await Promise.all(
              device?.data?.map(async (item) => {
                const relationData = await fetchData(
                  "GET",
                  relationInfo + "?fromId=" + item?.id.id + "&fromType=ASSET",
                  {
                    "X-Authorization":
                      "Bearer " + localStorage.getItem("token"),
                  },
                  {}
                );
                // eslint-disable-next-line
                relationData?.map((relation) => {
                  let allRelationId = {};
                  allRelationId.BuildingId = relation.from.id;
                  allRelationId.BuildingName = item?.name;
                  allRelationId.CoilId = relation.to.id;
                  allRelationId.CoilName = relation.toName;
                  allRelationData.push(allRelationId);
                });
                if (firstCoilId === "") {
                  firstCoilId = relationData[relationData.length - 1].to.id;
                }
                // activeSnapCoil(relationData[0].to.id);
              })
            );

            firstCoil.entityType = "DEVICE";
            firstCoil.id = firstCoilId;
            activeCoil(firstCoil);
            activeSnapCoil(firstCoil);

            addRelation(allRelationData);
            addBuilding(device?.data);
            if (device?.data[0]) {
              activeBuilding(device?.data[0].id);
            }
          } else if (device?.errorCode === 11) {
            navigator("/login");
            localStorage.clear();
          }
        };
        getDevices();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="layout-sidebar bg-white"
      style={{
        transform: isMobileView
          ? "translate(0%)"
          : window.screen.width > 922
          ? "translate(0%)"
          : "translate(-100%)",
      }}
    >
      <div className="d-block d-md-none d-flex justify-content-end my-3">
        <button
          className="border-none bg-white text-right"
          onClick={handleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="24"
            height="24"
            x="0"
            y="0"
            viewBox="0 0 320.591 320.591"
            className=""
          >
            <g>
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                fill="#000000"
                opacity="1"
                data-original="#000000"
                className=""
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                fill="#000000"
                opacity="1"
                data-original="#000000"
                className=""
              ></path>
            </g>
          </svg>
        </button>
      </div>

      <div className="coils-container">
        <div className="">
          <div className="coils-header" style={{ paddingLeft: "10px" }}>
            Most Recent
          </div>

          <ul className="tag-list recent-choice-list">
            {isSnapshotRoute ? (
              <>
                {recentSnapList &&
                  recentSnapList
                    .sort(
                      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                    )
                    ?.slice(0, 3)
                    ?.map((item, index) => (
                      <MostRecent
                        // isRecent={true}
                        key={index}
                        customClass="tag__label_snapshot"
                        item={item}
                        handleRecentChoice={(item) => activeSnapCoil(item.id)}
                      />
                    ))}
              </>
            ) : (
              <>
                {activeList &&
                  activeList
                    ?.reverse()
                    .sort(
                      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
                    )
                    ?.slice(0, 3)
                    ?.map((item, index) => (
                      <MostRecent
                        isRecent={true}
                        key={index}
                        index={index}
                        customClass="tag__label"
                        item={item}
                        handleRecentChoice={(item) => {
                          activeCoil(item.id);
                          removeRecentCoil(item.id);
                        }}
                      />
                    ))}
              </>
            )}
          </ul>
        </div>

        <div className="accordion">
          <div className="layout-sidebar-title">
            Select 1 Gateway to view individually, or choose up to 3 Gateways
            for comparison.
          </div>
          {isSnapshotRoute ? (
            <AhuSmartCoil
              Buildings={buildingList || []}
              AhuSmartCoilData={activeSnapList || []}
              handleRecentAhu={(item) => {
                const currentActiveCoilId = coilList?.filter((coil) => {
                  return coil?.id.id === item?.id.id && !coil.active;
                })[0]?.id;
                if (
                  currentActiveCoilId !== undefined &&
                  activeList.length < 3
                ) {
                  activeCoil(currentActiveCoilId);
                }
                activeSnapCoil(item.id);
              }}
            />
          ) : (
            <AhuSmartCoil
              Buildings={buildingList || []}
              AhuSmartCoilData={coilList || []}
              handleRecentAhu={(item) => {
                if (item?.active) {
                  activeCoil(item.id);
                  removeRecentCoil(item.id);
                } else {
                  activeCoil(item.id);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
  activeList: state.coilList.activeList,
  activeSnapList: state.coilList.activeSnapList,
  buildingList: state.buildingList.buildingList,
  recentList: state.coilList.recentList,
  recentSnapList: state.coilList.recentSnapList,
  relationList: state.buildingList.relationList,
});
export default connect(mapStateToProps, {
  addCoil,
  activeCoil,
  activeSnapCoil,
  addBuilding,
  addRelation,
  activeBuilding,
  removeRecentCoil,
  removeSnapRecentCoil,
})(Sidebar);
