import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminConsoleDataTable from "../AdminConsoleDataTable";
import fetchData from "../../../../axios/fetchData";
import {
  formattedDate,
  formattedDateString,
} from "../../../../axios/formattedDate";
import { coilGateways } from "../../../../axios/serverUrl";
import CustomLoader from "../../../../component/Loader/Loader";

const CoilGateways = ({ coilList }) => {
  const navigator = useNavigate();
  const [coilGatewayTableJson, setCoilGatewayTableJson] = useState([]);
  const [coilGatewayDataCount, setCoilGatewayDataCount] = useState("");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (JSON.parse(localStorage.userDetail)?.authority !== "TENANT_ADMIN") {
      fetchCurrentCustomerCoilGateways();
    } else {
      fetchCoilGateways();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCoilGateways = async () => {
    try {
      setLoading(true);
      const response = await fetchData(
        "GET",
        `${coilGateways}?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
          "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        {}
      );
      if (response?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
      setAllData(response?.data, false);
    } catch (error) {
      console.error("Error fetching coil gateways:", error);
    }
  };

  const fetchCurrentCustomerCoilGateways = async () => {
    try {
      setLoading(true);
      if (coilList?.length > 0) {
        setAllData(coilList, true);
      }
    } catch (error) {
      console.error("Error fetching coil gateways:", error);
    }
  };

  const setAllData = (response, isCustomer) => {
    if (isCustomer) {
      var { firstName, lastName } = JSON.parse(
        localStorage.getItem("userDetail")
      );
    }
    if (response) {
      const coilGatewayTableJson = response?.map((coilGatewayData) => ({
        image: <></>,
        imageText: coilGatewayData.name,
        column1data: isCustomer
          ? firstName + " " + lastName
          : coilGatewayData.customerTitle,
        column2data: coilGatewayData.type,
        column3data: (
          <div
            href="#"
            data-toggle="tooltip"
            data-placement="top"
            style={{ cursor: "pointer" }}
            title={formattedDate(coilGatewayData.createdTime)}
          >
            {formattedDateString(coilGatewayData.createdTime)}
          </div>
        ),
      }));
      setCoilGatewayDataCount(
        <>
          All ({response.length})&nbsp;&nbsp;|&nbsp;&nbsp;SmartCoil (
          {
            response.filter(
              (coilGatewayData) => coilGatewayData.type === "SmartCoil Gen1"
            ).length
          }
          )&nbsp;&nbsp;|&nbsp;&nbsp;BiomikMK2 (
          {
            response.filter(
              (coilGatewayData) => coilGatewayData.type === "Biomik MK2"
            ).length
          }
          )&nbsp;&nbsp;|&nbsp;&nbsp;HUB (
          {
            response.filter((coilGatewayData) => coilGatewayData.type === "HUB")
              .length
          }
          )
        </>
      );
      setCoilGatewayTableJson(coilGatewayTableJson);
      setLoading(false);
    } else if (response.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    }
  };
  // const renderButton = (label, onClick) => (
  //   <button
  //     type="button"
  //     className="btn btn-outline-secondary btn-sm new-user-btn"
  //     onClick={onClick}
  //   >
  //     {label}
  //   </button>
  // );

  return (
    <div>
      {/* <div className="new-user-btn-wrapper mb-md-0 mb-3 float-md-right">
        {renderButton("Add SmartCoil Gateways")}
      </div> */}

      {/* <div className="select-wrapper d-block d-md-flex mb-3 mb-md-0">
        <div className="d-flex mb-3 mb-md-0">
          <select className="form-select" aria-label="select example">
            <option value="" selected>
              Bulk Actions
            </option>
            <option value="1">Actions One</option>
            <option value="2">Actions Two</option>
            <option value="3">Actions Three</option>
          </select>
          {renderButton("Apply")}
        </div>

        <div className="d-flex">
          <select className="form-select" aria-label="select example">
            <option value="" selected>
              Change role to
            </option>
            <option value="1">role One</option>
            <option value="2">role Two</option>
            <option value="3">role Three</option>
          </select>
          {renderButton("Change")}
        </div>
      </div> */}

      {/* Table content  */}
      <AdminConsoleDataTable
        dataCount={coilGatewayDataCount}
        tableHeadings={[
          "Gateways",
          "AHU Name",
          "Device Profile",
          "Created time",
        ]}
        tableJson={coilGatewayTableJson}
      />
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
});
export default connect(mapStateToProps, {})(CoilGateways);
