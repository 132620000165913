import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import PageListing from "../PageListing";

const CoilListing = ({ coilList, relationList }) => {
  const [searchParams] = useSearchParams();
  const [buildingName, setBuildingName] = useState("");
  const [coils, setCoils] = useState(coilList);
  useEffect(() => {
    const buildingId = searchParams.get("BuildingId");

    if (buildingId) {
      const coilIdsForCurrentBuilding = relationList
        ?.filter((item) => item?.BuildingId === buildingId)
        ?.map((item) => item?.CoilId);

      const currentBuilding = relationList?.find(
        (item) => item.BuildingId === buildingId
      );
      const buildingName = currentBuilding
        ? currentBuilding.BuildingName
        : undefined;

      setBuildingName(buildingName);

      const filteredCoilList = coilList?.filter((mainCoil) =>
        coilIdsForCurrentBuilding.includes(mainCoil?.id?.id)
      );

      setCoils(filteredCoilList);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="custom-container listing-wrapper">
      {coils.length > 0 ? (
        <>
          <h2 className="listing-title">{buildingName}</h2>
          <span className="listing-sub-title">{coils.length} AHUs</span>
          <div className="divide" />
          <PageListing listData={coils} toggleView={true} />
        </>
      ) : (
        <div className="empty-search-string">
          This building has not attached any coil.
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
  relationList: state.buildingList.relationList,
});
export default connect(mapStateToProps, {})(CoilListing);
