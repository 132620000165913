import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
const AdminConsoleDataTable = (props) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setIsDataLoaded(true);
    }, 20);
  });
  const fadeInProps = {
    opacity: isDataLoaded ? 1 : 0,
  };
  const columns =
    props.tableHeadings.length === 5
      ? [
          {
            name: props.tableHeadings[0],
            cell: (row) => (
              <>
                {row.image}
                {row.imageText}
              </>
            ),
            width: "320px",
          },
          {
            name: props.tableHeadings[1],
            selector: (row) => <>{row.column1data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[2],
            selector: (row) => <>{row.column2data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[3],
            selector: (row) => <>{row.column3data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[4],
            selector: (row) => <>{row.column4data}</>,
            sortable: true,
          },
        ]
      : [
          {
            name: props.tableHeadings[0],
            cell: (row) => (
              <>
                {row.image}
                {row.imageText}
              </>
            ),
          },
          {
            name: props.tableHeadings[1],
            selector: (row) => <>{row.column1data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[2],
            selector: (row) => <>{row.column2data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[3],
            selector: (row) => <>{row.column3data}</>,
            sortable: true,
          },
        ];
  return (
    <div className="test-animation" style={fadeInProps}>
      <div className="user-content-wrapper">
        <p>{props.dataCount}</p>
        {props.dataCount !== "" && props.tableJson.length > 0 ? (
          <DataTable
            columns={columns}
            data={props.tableJson}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 40, 50, 100]}
            pagination
            selectableRows
            noDataComponent={"No Records Found!!"}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default AdminConsoleDataTable;
