import RenderChart from '../../../component/RenderData/RenderChart';
import lineChartDataOption from "./PredictiveLineChartData";

function FounlingMetrics() {
  return (
    <RenderChart
      chartOptions={lineChartDataOption}
      chartTitle="Fouling Metrics"
      chartSubTitle="COIL FOULING LEVEL"
      dropdownOptions={['ahu_flvl']}
  />
  );
}
export default FounlingMetrics;