import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminConsoleDataTable from "../AdminConsoleDataTable";
import fetchData from "../../../../axios/fetchData";
import {
  formattedDate,
  formattedDateString,
} from "../../../../axios/formattedDate";
import { customers, customer } from "../../../../axios/serverUrl";
import CustomLoader from "../../../../component/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const Customers = ({ sendDataToParent }) => {
  const navigator = useNavigate();
  const token = localStorage.getItem("token");
  const [customerTableJson, setCustomerTableJson] = useState([]);
  const [customerDataCount, setCustomerDataCountString] = useState("");
  const [newCustomerName, setNewCustomerName] = useState("");
  const [newCustomerEmail, setNewCustomerEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const openModal = () => {
  //   setModalIsOpen(true);
  // };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchUsers = async (CustomerId, customerName) => {
    try {
      setLoading(true);
      const response = await fetchData(
        "GET",
        `${customer}/${CustomerId}/users?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
          "X-Authorization": `Bearer ${token}`,
        }
      );
      if (response?.data) {
        const userTableJson = response?.data?.map((userData) => ({
          image: (
            <div className="circle-highlight mr-2">{userData.name[0]}</div>
          ),
          imageText: userData.email,
          column1data: <div>{userData.firstName}</div>,
          column2data: <div>{userData.lastName}</div>,
          column3data: userData.email,
          column4data: (
            <div
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              style={{ cursor: "pointer" }}
              title={formattedDate(userData.createdTime)}
            >
              {formattedDateString(userData.createdTime)}
            </div>
          ),
        }));
        sendDataToParent(
          userTableJson,
          <>All ({response.totalElements})</>,
          customerName,
          CustomerId
        );
        setLoading(false);
      } else if (response?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await fetchData(
        "GET",
        `${customers}?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
          "X-Authorization": `Bearer ${token}`,
        }
      );
      if (response?.data) {
        const customerTableJson = response?.data?.map((customerData) => ({
          image: (
            <div className="circle-highlight mr-2">{customerData.name[0]}</div>
          ),
          imageText: customerData.email,
          column1data: (
            <div
              className="customer-name-link"
              onClick={() => {
                fetchUsers(customerData.id.id, customerData.name);
              }}
            >
              {customerData.name}
            </div>
          ),
          column2data: customerData.email,
          column3data: (
            <div
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              style={{ cursor: "pointer" }}
              title={formattedDate(customerData.createdTime)}
            >
              {formattedDateString(customerData.createdTime)}
            </div>
          ),
        }));
        setCustomerDataCountString(
          <>
            All ({response.totalElements})&nbsp;&nbsp;|&nbsp;&nbsp;Administrator
            (
            {
              response.data.filter(
                (coilGatewayData) =>
                  coilGatewayData.id.entityType === "ADMINISTRATOR"
              ).length
            }
            )&nbsp;&nbsp;|&nbsp;&nbsp;Customer (
            {
              response.data.filter(
                (coilGatewayData) =>
                  coilGatewayData.id.entityType === "CUSTOMER"
              ).length
            }
            )
          </>
        );

        setCustomerTableJson(customerTableJson);
        setLoading(false);
      } else if (response.errorCode === 11) {
        navigator("/login");
        localStorage.clear();
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const createNewCustomer = async () => {
    try {
      setLoading(true);
      if (newCustomerName != null || newCustomerName !== "") {
        const body = {
          title: newCustomerName,
          email: newCustomerEmail,
          tenantId: {
            entityType: "TENANT",
            id: JSON.parse(localStorage.getItem("userDetail")).tenantId,
          },
        };
        const data = await fetchData(
          "POST",
          customer,
          {
            "X-Authorization": `Bearer ${token}`,
          },
          body
        );
        if (data?.id) {
          fetchCustomers();
          setLoading(false);
          setModalIsOpen(false);
        } else if (data?.errorCode === 11) {
          setLoading(false);
          navigator("/login");
          localStorage.clear();
        } else {
          setLoading(false);
          toast.error(data?.message);
        }
      } else {
        setLoading(false);
        toast.error("Customer Required");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching customers:", error);
    }
  };
  // const renderButton = (label, onClick) => (
  //   <button
  //     type="button"
  //     className="btn btn-outline-secondary btn-sm new-user-btn"
  //     onClick={onClick}
  //   >
  //     {label}
  //   </button>
  // );

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <h6 className="admin-subtitle">Add New Customer</h6>
        <ToastContainer />
        <input
          className="form-control-normal mb-3"
          placeholder="Customer Name"
          onChange={(e) => setNewCustomerName(e.target.value)}
          value={newCustomerName}
        />
        <input
          className="form-control-normal mb-3"
          placeholder="Customer Email"
          onChange={(e) => setNewCustomerEmail(e.target.value)}
          value={newCustomerEmail}
        />
        <button
          type="button"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          cancel{" "}
        </button>
        <button
          type="button"
          className="btn btn-outline-success"
          onClick={createNewCustomer}
        >
          Add Customer
        </button>
      </Modal>
      {/* <div className="new-user-btn-wrapper mb-md-3 mb-3 float-md-right">
        {renderButton("Add New Customer", openModal)}
      </div> */}

      <div className="select-wrapper d-block d-md-flex mb-3 mb-md-0">
        {/* <div className="d-flex mb-3 mb-md-0">
          <select className="form-select" aria-label="select example">
            <option value="1">Bulk Actions</option>
            <option value="1">Actions One</option>
            <option value="2">Actions Two</option>
            <option value="3">Actions Three</option>
          </select>
          {renderButton("Apply")}
        </div>

        <div className="d-flex">
          <select className="form-select" aria-label="select example">
            <option value="1">Change role to</option>
            <option value="1">role One</option>
            <option value="2">role Two</option>
            <option value="3">role Three</option>
          </select>
          {renderButton("Change")}
        </div> */}
      </div>

      {/* Table content  */}
      <AdminConsoleDataTable
        dataCount={customerDataCount}
        tableHeadings={[" ", "Name", "Email", "Created time"]}
        tableJson={customerTableJson}
      />
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};

export default Customers;
